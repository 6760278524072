import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const QuestionSetup = React.lazy(() =>
  import("modules/master/questionBank/questionSetup")
);

const routes = {
  element: <QuestionSetup />,
  title: "Question Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    "/question/types",
    "/question/sourcetags",
    "/question/competitivetags",
    "/question/difficultylevels",
    "/question/questions/create",
    "/question/questions/edit/:id",
  ],
};

export default routes;
