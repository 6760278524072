import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const StudentLogin = React.lazy(() => import("modules/student/login"));

const routes = {
  title: "NSAT Login",
  permittedRole: [USER_ROLE.PUBLIC],
  path: ["studentlogin"],
  element: <StudentLogin />,
  type: "item",
};

export default routes;
