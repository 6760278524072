import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const UserPayroll = React.lazy(() => import("modules/payRoll/userPayroll"));

const routes = {
  element: <UserPayroll />,
  title: "User Payroll",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/payroll/userpayrolls"],
};

export default routes;
