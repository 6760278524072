import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const PaymentTransactions = React.lazy(() => import("modules/account/paymentTransactions"));

const routes = {
    element: <PaymentTransactions />,
    title: "Transactions History",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    type: "item",
    path: ["/account/paymenttransactions"],
};

export default routes;
