import PropTypes from "prop-types";
import EntranceStudentAdmitCardProvider from "./entranceStudentAdmitCardContext";
import EntranceStudentApplicationProvider from "./entranceStudentApplicationContext";
import EntranceStudentNotificationProvider from "./entranceStudentNotificationContext";

const EntranceStudentContextProvider = ({ children }) => {
  return (
    <EntranceStudentNotificationProvider>
      <EntranceStudentApplicationProvider>
        <EntranceStudentAdmitCardProvider>
          {children}
        </EntranceStudentAdmitCardProvider>
      </EntranceStudentApplicationProvider>
    </EntranceStudentNotificationProvider>
  );
};

export default EntranceStudentContextProvider;

EntranceStudentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
