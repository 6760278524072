import PropTypes from "prop-types";
import AdmissionStudentContextProvider from "./admission";
import EntranceStudentContextProvider from "./entrance";
import PaymentTransactionProvider from "./paymentTransactionContext";
import PaymentTransactionDateProvider from "./paymentTransactionDateContext";
import StudentFeesReceiptProvider from "./studentFeesReceiptContext";
import StudentFeesStructureInstallmentByDueDateProvider from "./studentFeesStructureInstallmentByDueDateContext";
import StudentFeesStructureInstallmentProvider from "./studentFeesStructureInstallmentContext";
import StudentRegistrationProvider from "./studentRegistrationContext";

const StudentContextProvider = ({ children }) => {
  return (
    <StudentFeesStructureInstallmentProvider>
      <PaymentTransactionDateProvider>
        <StudentFeesStructureInstallmentByDueDateProvider>
          <StudentFeesReceiptProvider>
            <PaymentTransactionProvider>
              <EntranceStudentContextProvider>
                <AdmissionStudentContextProvider>
                  <StudentRegistrationProvider>
                    {children}
                  </StudentRegistrationProvider>
                </AdmissionStudentContextProvider>
              </EntranceStudentContextProvider>
            </PaymentTransactionProvider>
          </StudentFeesReceiptProvider>
        </StudentFeesStructureInstallmentByDueDateProvider>
      </PaymentTransactionDateProvider>
    </StudentFeesStructureInstallmentProvider>
  );
};

export default StudentContextProvider;

StudentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
