import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const EntranceStudentCommunication = React.lazy(() =>
  import("modules/student/entrance/entranceStudentCommunication")
);

const routes = {
  title: "Communication",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/entrance/communications"],
  element: <EntranceStudentCommunication />,
  type: "item",
};

export default routes;
