import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { CSS_CONSTANTS } from "constants/AppConstant";
import { default as PropTypes, default as PropsTypes } from "prop-types";
import AppLogo from "../AppLogo";
import HorizontalNav from "../HorizontalNav";
import NotificationBar from "../NotificationBar";
import HeaderNavWrapper from "./HeaderNavWrapper";

const AppHeader = ({ toggleNavCollapsed, routesConfig }) => {
  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        boxShadow: "none",
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        backgroundColor: "background.paper",
        width: {
          xs: "100%",
        },
        zIndex: CSS_CONSTANTS.Z_INDEX.HEADER,
      }}
      className="app-bar"
    >
      <NotificationBar />
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { xs: 56, sm: 70, lg: 100 },
          px: { xs: 0 },
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          backgroundColor: "background.paper",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: { lg: 1140, xl: 1420 },
            mx: "auto",
            px: 5,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Hidden lgUp>
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(2),
                color: "text.secondary",
              }}
              edge="start"
              className="menu-btn"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleNavCollapsed}
              size="large"
            >
              <MenuIcon
                sx={{
                  width: 35,
                  height: 35,
                }}
              />
            </IconButton>
          </Hidden>

          <Box
            sx={{
              "& .app-logo": {
                pl: 0,
              },
            }}
          >
            <AppLogo sx={{ p: { md: 0.5, xs: 0.5, lg: 0 } }} />
          </Box>

          <Hidden lgDown>
            <HeaderNavWrapper>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: { lg: 1140, xl: 1436 },
                  mx: "auto",
                  px: 5,
                  pl: 10,
                }}
              >
                <HorizontalNav routesConfig={routesConfig} />
              </Box>
            </HeaderNavWrapper>
          </Hidden>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;

AppHeader.propTypes = {
  toggleNavCollapsed: PropTypes.func,
  routesConfig: PropsTypes.array.isRequired,
};
