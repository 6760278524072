// React imports
import { createContext, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state
import { DISPATCH_TYPES } from "constants/ActionTypes";
import reducer from "reducers/student/admission/admissionStudentFeesStructureReducer";
import { useAdmissionStudentAdmissionQueueContext } from "./admissionStudentAdmissionQueueContext";

// context to be used in the app
const AdmissionStudentFeesStructureContext = createContext();

// hook to use the context states in the ui
const useAdmissionStudentFeesStructureContext = () => {
  return useContext(AdmissionStudentFeesStructureContext);
};
export { useAdmissionStudentFeesStructureContext };

// Initial State
const initialState = {
  data: [],
  count: 0,
  item: {},
};

// provider of the context
const AdmissionStudentFeesStructureProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();
  const { setItem: setAdmissionStudentAdmissionQueue } =
    useAdmissionStudentAdmissionQueueContext();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName = API_NAMES.STUDENT.ADMISSION.ADMISSION_STUDENT_FEES_STRUCTURE;

  // Fetches whole records from DB
  const getList = (params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  };


  // Fetches single record in records of DB
  const getItem = (_id, params, onSuccess = () => { }, onError) => {
    GetAPI(apiName, dispatch, _id, params, onSuccess, onError);
  };

  // Update single record in records of DB
  const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose,
      "",
      (data) => {
        setAdmissionStudentAdmissionQueue(data);
        setItem(data);
      }
    );
  };

  // Add new record in records of DB
  const createItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    CreateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose,
      "",
      (data) => {
        setAdmissionStudentAdmissionQueue(data);
        setItem(data);
      }
    );
  };

  // Delete on or may records from records in DBs
  const deleteItems = (params, _ids) => {
    DeleteAPI(apiName, dispatch, params, _ids);
  };
  const setItem = (item) => {
    dispatch({
      type: DISPATCH_TYPES.SET_ITEM,
      payload: { data: item },
    });
  };

  return (
    <AdmissionStudentFeesStructureContext.Provider
      value={{
        data: state.data,
        count: state.count,
        item: state.item,
        setItem,
        getList,
        getItem,
        updateItem,
        createItem,
        deleteItems,
      }}
    >
      {children}
    </AdmissionStudentFeesStructureContext.Provider>
  );
};

export default AdmissionStudentFeesStructureProvider;
