import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const UserRegister = React.lazy(() => import("modules/user/userRegistration"));

const routes = {
  title: "Registration",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/user/registration"],
  element: <UserRegister />,
  type: "item",
};

export default routes;
