import React from "react";
import { USER_ROLE } from "constants/AppConstant";

const AdmissionStudentSchoolRegistration = React.lazy(
  () => import("modules/student/admission/admissionStudentRegistrationAdmin")
);
const routes = {
  path: ["/admin/school/student/registration"],
  element: <AdmissionStudentSchoolRegistration />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  title: "School Registration",
  type: "item",
};

export default routes;
