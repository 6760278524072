import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const UserProfile = React.lazy(() => import("modules/user/userProfile"));

const routes = {
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/user/profile"],
  element: <UserProfile />,
};

export default routes;
