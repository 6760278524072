import React from "react";
import PropTypes from "prop-types";
import AdmissionStudentAdmissionQueueContextProvider from "./admissionStudentAdmissionQueueContext";
import AdmissionStudentProfileProvider from "./admissionStudentProfileContext";
import AdmissionStudentAdmissionProvider from "./admissionStudentAdmissionContext";
import AdmissionStudentFeesStructureProvider from "./admissionStudentFeesStructureContext";

const AdmissionStudentContextProvider = ({ children }) => {
  return (
    <AdmissionStudentAdmissionQueueContextProvider>
      <AdmissionStudentFeesStructureProvider>
        <AdmissionStudentAdmissionProvider>
          <AdmissionStudentProfileProvider>
            {children}
          </AdmissionStudentProfileProvider>
        </AdmissionStudentAdmissionProvider>
      </AdmissionStudentFeesStructureProvider>
    </AdmissionStudentAdmissionQueueContextProvider>
  );
};

export default AdmissionStudentContextProvider;

AdmissionStudentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
