// React imports
import { createContext, useCallback, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state
import { DISPATCH_TYPES } from "constants/ActionTypes";
import reducer from "reducers/master/system/permissionReducer";

// context to be used in the app
const PermissionContext = createContext();

// hook to use the context states in the ui
const usePermissionContext = () => {
  return useContext(PermissionContext);
};
export { usePermissionContext };

// Initial State
const initialState = {
  data: [],
  count: 0,
  permissionsList: []
};

// provider of the context
const PermissionProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName = API_NAMES.MASTER.SYSTEM.PERMISSION;

  // Fetches whole records from DB
  const getList = useCallback((params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  }, []);

  // Fetches whole records from DB
  const getListByUser = useCallback((params, callbackFunction) => {
    return GetListAPI(apiName + "byuser", dispatch, params, callbackFunction, null, DISPATCH_TYPES.SET_ITEM);
  }, []);

  // Fetches single record in records of DB
  const getItem = useCallback((_id) => {
    GetAPI(apiName, dispatch, _id);
  }, []);

  // Update single record in records of DB
  const updateItem = useCallback((formData, setSubmitting, resetForm, handleAddClose, dispatchType) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose,
      null,
      null,
      dispatchType
    );
  }, [])

  // Add new record in records of DB
  const createItem = useCallback((formData, setSubmitting, resetForm, handleAddClose) => {
    CreateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  }, [])

  // Delete on or may records from records in DB
  const deleteItems = useCallback((params, _ids) => {
    DeleteAPI(apiName, dispatch, params, _ids);
  }, [])

  return (
    <PermissionContext.Provider
      value={{
        data: state.data,
        count: state.count,
        permissionsList: state.permissionsList,
        getList,
        getItem,
        updateItem,
        createItem,
        deleteItems,
        getListByUser,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
