import React from "react";

const Login = React.lazy(() => import("modules/auth/Login"));
const Register = React.lazy(() => import("modules/auth/Register"));
const ForgotPassword = React.lazy(() => import("modules/auth/ForgetPassword"));

export const authRouteConfig = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  },
];
