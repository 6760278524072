import React from "react";
import { alpha, Box } from "@mui/material";
import PropsTypes from "prop-types";

const FooterWrapper = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "none",
        border: "1px solid #e8e5dd",
        backgroundColor: "black",
        // backgroundColor: (theme) => theme.palette.background.paper,
        backgroundImage: (theme) =>
          `linear-gradient(${alpha(theme.palette.common.white, 0.05)}, ${alpha(
            theme.palette.common.white,
            0.05
          )})`,
        color: (theme) => theme.palette.primary.contrastText,
        "& .footerContainer": {
          alignItems: "center",
          flexDirection: { lg: "row", md: "column", xs: "column" },
          display: "flex",
          justifyContent: "space-between",
          maxWidth: { lg: 1020, xl: 1020 },
          p: 3.5,
          px: 5,
          // padding: { xs: '5px 20px', md: '5px 32px', xl: '10px 32px' },
          margin: "0 auto",
        },

        mt: 5,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default FooterWrapper;

FooterWrapper.propTypes = {
  children: PropsTypes.node,
  sidebarHeaderColor: PropsTypes.string,
};
