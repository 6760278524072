import AppDialog from "@crema/components/AppDialog";
import AppIconButton from "@crema/components/AppIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { CSS_CONSTANTS } from "constants/AppConstant";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { FaFilter } from "react-icons/fa";
import AppMessages from "../../../AppMessages";
import AppNotifications from "../../../AppNotifications";
import AppLogo from "../../components/AppLogo";
import CommonFilter from "./CommonFilter";
import PageSearchBar from "./PageSearchBar";

const AppHeader = (props) => {
  const {
    isCollapsed,
    setCollapsed,
    toggleNavCollapsed,
    dynamicRoutes,
    searchBarRef,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isCommonFilter, setIsCommonFilter] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCommonFilterClose = useCallback(
    () => setIsCommonFilter(false),
    []
  );
  const handleCommonFilterOpen = useCallback(() => setIsCommonFilter(true), []);

  return (
    <AppBar
      position="relative"
      color="inherit"
      sx={{
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: "background.paper",
        transition: "width 0.5s ease",
        width: "100%",
        zIndex: CSS_CONSTANTS.Z_INDEX.HEADER,
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { xs: 45, sm: 45 },
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5, md: 7.5 },
        }}
      >
        <Hidden lgDown>
          <IconButton
            sx={{ color: "text.secondary" }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setCollapsed(!isCollapsed)}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 25,
                height: 25,
              }}
            />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            sx={{ color: "text.secondary" }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleNavCollapsed}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 25,
                height: 25,
              }}
            />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            "& .logo-text": {
              display: { xs: "none", sm: "block" },
            },
          }}
        >
          <AppLogo sx={{ height: 40 }} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            px: 6,
          }}
        >
          <Hidden mdUp>
            <AppIconButton
              icon={<FaFilter />}
              placement="bottom"
              title="More"
              sx={{ float: "right" }}
              buttonProps={{
                onClick: handleCommonFilterOpen,
              }}
            />
          </Hidden>

          <Hidden mdDown>
            <CommonFilter
              gridItemSize={{
                academicYear: { lg: 1.95, md: 2.5 },
                branchCity: { lg: 1.95, md: 2.5 },
                branch: { lg: 4.7, md: 4 },
              }}
            />
          </Hidden>
        </Box>

        <PageSearchBar dynamicRoutes={dynamicRoutes} ref={searchBarRef} />

        <Box sx={{ ml: 4 }}>
          <Hidden smDown>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppNotifications />
              </Box>
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppMessages />
              </Box>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppIconButton
                  icon={<MoreVertIcon />}
                  placement="bottom"
                  title="More"
                  buttonProps={{
                    onClick: handleClick,
                  }}
                />
              </Box>
            </Box>
          </Hidden>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <AppNotifications isMenu />
            </MenuItem>
            <MenuItem>
              <AppMessages isMenu />
            </MenuItem>
            <MenuItem>Setting</MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      <AppDialog
        open={isCommonFilter}
        onClose={handleCommonFilterClose}
        title="Filter"
        dialogIcon={<FaFilter />}
        dividers
      >
        <CommonFilter
          gridItemSize={12}
          selectSize={"large"}
          handleCommonFilterClose={handleCommonFilterClose}
        />
      </AppDialog>
    </AppBar>
  );
};
export default AppHeader;

AppHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  toggleNavCollapsed: PropTypes.func,
};
