import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const FeesConcessionByDivision = React.lazy(() => import("modules/account/feesConcessionByDivision"));

const routes = {
    element: <FeesConcessionByDivision />,
    title: "Concession By Division",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    type: "item",
    path: ["/account/feesconcessionbydivisions"],
};

export default routes;
