import PropTypes from "prop-types";
import SalaryPaymentMethodProvider from "./salaryPaymentMethodContext";
import StatutoryComponentProvider from "./statutoryComponentContext";
import UserPaidSalaryTransactionProvider from "./userPaidSalaryTransactionContext";
import UserSalaryProvider from "./userSalaryContext";
import UserSalaryDeductionProvider from "./userSalaryDeductionContext";
import UserSalaryTransactionProvider from "./userSalaryTransactionContext";

const PayRollProvider = ({ children }) => {
  return (
    <SalaryPaymentMethodProvider>
      <StatutoryComponentProvider>
        <UserPaidSalaryTransactionProvider>
          <UserSalaryProvider>
            <UserSalaryDeductionProvider>
              <UserSalaryTransactionProvider>
                {children}
              </UserSalaryTransactionProvider>
            </UserSalaryDeductionProvider>
          </UserSalaryProvider>
        </UserPaidSalaryTransactionProvider>
      </StatutoryComponentProvider>
    </SalaryPaymentMethodProvider>
  );
};

export default PayRollProvider;

PayRollProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
