// React imports
import { createContext, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state 
import { DISPATCH_TYPES } from "constants/ActionTypes";
import reducer from "reducers/competitiveExam/competitiveExamStudentApplicationReducer";

// context to be used in the app
const CompetitiveExamStudentApplicationContext = createContext();

// hook to use the context states in the ui
const useCompetitiveExamStudentApplicationContext = () => {
    return useContext(CompetitiveExamStudentApplicationContext);
};
export { useCompetitiveExamStudentApplicationContext };

// Initial State
const initialState = {
    data: [],
    count: 0,
    item: {},
    exportList: [],
    printList: [],
};

// provider of the context
const CompetitiveExamStudentApplicationProvider = ({ children }) => {
    // API helper functions to hit APi's
    const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();

    // Reducer to set state
    const [state, dispatch] = useReducer(reducer, initialState);

    // Name of the api to hit
    const apiName = API_NAMES.COMPETITIVE_EXAM.COMPETITIVE_EXAM_STUDENT_APPLICATION;

    // Fetches whole records from DB
    const getList = (params, callbackFunction) => {
        return GetListAPI(apiName, dispatch, params, callbackFunction);
    };

    // Fetches whole records from DB
    const getExportList = (params, callbackFunction) => {
        return GetListAPI(apiName + "export", dispatch, params, callbackFunction, null, DISPATCH_TYPES.EXPORT);
    };

    // Fetches single record in records of DB
    const getItem = (_id, params, onSuccess, onError) => {
        GetAPI(apiName, dispatch, _id, params, onSuccess, onError);
    };


    const getPrintList = (params, callbackFunction) => {
        return GetListAPI(apiName, dispatch, params, callbackFunction, null, DISPATCH_TYPES.PRINT);
    };

    // Update single record in records of DB
    const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
        UpdateAPI(
            apiName,
            dispatch,
            formData,
            setSubmitting,
            resetForm,
            handleAddClose
        );
    };

    // Add new record in records of DB
    const createItem = (formData, setSubmitting, resetForm, handleAddClose, callbackFunction) => {
        CreateAPI(
            apiName,
            dispatch,
            formData,
            setSubmitting,
            resetForm,
            handleAddClose,
            "",
            callbackFunction
        );
    };

    // Delete on or may records from records in DB
    const deleteItems = (params, _ids, onSuccess, onError) => {
        DeleteAPI(apiName, dispatch, params, _ids, "", onSuccess, onError);
    };

    return (
        <CompetitiveExamStudentApplicationContext.Provider
            value={{
                data: state.data,
                count: state.count,
                item: state.item,
                exportList: state.exportList,
                printList: state.printList,
                getPrintList,
                getExportList,
                getList,
                getItem,
                updateItem,
                createItem,
                deleteItems,
            }}
        >
            {children}
        </CompetitiveExamStudentApplicationContext.Provider>
    );
};

export default CompetitiveExamStudentApplicationProvider;
