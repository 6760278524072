import PropTypes from "prop-types";
import { createContext, useContext, useState } from "react";

import defaultAppSetting from "constants/DefaultAppSetting";

const AppSettingContext = createContext();

export const useAppSettingContext = () => useContext(AppSettingContext);

const AppSettingContextProvider = ({ children }) => {
  const [appSetting, updateAppSetting] = useState(defaultAppSetting);

  return (
    <AppSettingContext.Provider
      value={{
        appSetting,
        updateAppSetting,
      }}
    >
      {children}
    </AppSettingContext.Provider>
  );
};

export default AppSettingContextProvider;

AppSettingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
