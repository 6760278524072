import Box from "@mui/material/Box";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import AppContentView from "../../AppContentView";
import AdminLayoutContainer from "./AdminLayoutContainer";
import AdminLayoutWrapper from "./AdminLayoutWrapper";
import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";

import { useEventListener } from "@crema/hooks/useEventListener";
import { useAppSettingContext } from "contexts/appSetting";

const AdminLayout = ({ dynamicRoutes, routes, routesConfig }) => {
  const { pathname } = useLocation();
  const [isCollapsed, setCollapsed] = useState(false);
  const [isNavCollapsed, setNavCollapsed] = useState(false);
  const { appSetting } = useAppSettingContext();

  const searchBarRef = useRef();

  const toggleNavCollapsed = () => {
    setNavCollapsed(!isNavCollapsed);
  };

  const handleIsKeyDown = useCallback((event) => {
    if (event.ctrlKey && event.key === "b") {
      setNavCollapsed((prev) => !prev);
      setCollapsed((prev) => !prev);
      event.preventDefault();
    }

    if (event.ctrlKey && event.key === "k") {
      searchBarRef?.current?.setIsFocused &&
        searchBarRef?.current?.setIsFocused(true);
      event.preventDefault();
      searchBarRef?.current?.input?.focus();
    }
  }, []);

  useEventListener("keydown", handleIsKeyDown);
  useEffect(() => {
    if (isNavCollapsed) setNavCollapsed(!isNavCollapsed);
  }, [pathname]);

  return (
    <AdminLayoutContainer>
      <AdminLayoutWrapper
        className={clsx("adminLayoutWrapper", {
          "mini-sidebar-collapsed": isCollapsed,
          appMainFixedHeader: appSetting?.headerType === "FIXED",
        })}
      >
        <AppSidebar
          routesConfig={routesConfig}
          isNavCollapsed={isNavCollapsed}
          toggleNavCollapsed={toggleNavCollapsed}
          sidebarTextColor={appSetting?.sidebar?.sidebarTextColor}
        />
        <Box className="mainContent">
          <AppHeader
            setCollapsed={setCollapsed}
            isCollapsed={isCollapsed}
            toggleNavCollapsed={toggleNavCollapsed}
            dynamicRoutes={dynamicRoutes}
            searchBarRef={searchBarRef}
          />
          <AppContentView routes={routes} />
        </Box>
        {/* <AppThemeSetting /> */}
      </AdminLayoutWrapper>
    </AdminLayoutContainer>
  );
};

export default AdminLayout;
