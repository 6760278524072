import React from "react";
import PropsTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import { FONTS } from "constants/AppEnum";

import { alpha } from "@mui/material";
import { CSS_CONSTANTS } from "constants/AppConstant";

const VerticalCollapseItem = ({ children, sidebarTextColor, ...rest }) => {
  return (
    <ListItem
      sx={{
        textTransform: "uppercase",
        height: 40,
        my: 0.25,
        pl: "31px",
        pr: 3.75,
        whiteSpace: "nowrap",
        transition: "all 0.4s ease",
        "& .nav-item-text": {
          fontWeight: FONTS.MEDIUM,
          color: alpha(sidebarTextColor, 0.7),
        },

        "& .nav-item-icon": {
          color: alpha(sidebarTextColor, 0.7),
          "& svg": {
            fontSize: 20,
          },
        },

        "& .nav-item-icon-arrow": {
          color: alpha(sidebarTextColor, 0.7),
        },

        "& .MuiIconButton-root": {
          mr: 3,
          padding: 0,
        },

        "& .MuiTouchRipple-root": {
          // zIndex: 10,
          zIndex: CSS_CONSTANTS.Z_INDEX.VERTICAL_COLLAPSE,
        },

        "&.open, &:hover, &:focus": {
          "& .nav-item-text": {
            fontWeight: FONTS.MEDIUM,
            color: sidebarTextColor,
          },

          "& .nav-item-icon": {
            color: sidebarTextColor,
          },

          "& .nav-item-icon-arrow": {
            color: sidebarTextColor,
          },
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      {...rest}
    >
      {children}
    </ListItem>
  );
};

export default VerticalCollapseItem;

VerticalCollapseItem.propTypes = {
  children: PropsTypes.node,
  sidebarTextColor: PropsTypes.string,
};
