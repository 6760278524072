import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const AttendanceSetup = React.lazy(() =>
  import("modules/master/communication/attendaceSetup")
);

const routes = {
  element: <AttendanceSetup />,
  title: "Attendance Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/attendance/deviceconfigurations", "/attendance/devices"],
};

export default routes;
