import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const FeesSetup = React.lazy(() => import("modules/master/academic/feesSetup"));

const routes = {
  element: <FeesSetup />,
  title: "Fees Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    "/fees/feesstructureinstallments",
    "/fees/types",
    "/fees/feesinstallmenttypes",
    "/fees/concessiongroups",
    "/fees/concessiontypes",
    "/fees/concessions",
    "/fees/receipts",
    "/fees/paymentmethods",
  ],
};

export default routes;
