import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import AppScrollbar from "../../../AppScrollbar";
import MainSidebar from "../../components/MainSidebar";
import Drawer from "@mui/material/Drawer";
import VerticalNav from "../VerticalNav";
import SidebarWrapper from "./SidebarWrapper";

const AppSidebar = (props) => {
  return (
    <Drawer
      anchor={props.position}
      open={props.isNavCollapsed}
      onClose={props.toggleNavCollapsed}
      classes={{
        root: clsx(props.variant),
        paper: clsx(props.variant),
      }}
      style={{ position: "absolute" }}
    >
      <SidebarWrapper className="standard-sidebar">
        <MainSidebar>
          <AppScrollbar
            sx={{
              py: 2,
              height: "calc(100vh - 70px) !important",
            }}
          >
            <VerticalNav routesConfig={props.routesConfig} />
          </AppScrollbar>
        </MainSidebar>
      </SidebarWrapper>
    </Drawer>
  );
};
export default AppSidebar;

AppSidebar.defaultProps = {
  variant: "",
  position: "left",
};

AppSidebar.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
  toggleNavCollapsed: PropTypes.func,
  routesConfig: PropTypes.array,
  isNavCollapsed: PropTypes.bool,
};
