import Layouts from "@crema/components/AppLayout";
import generateDynamicRoutesWithRoleAndPermissions from "@crema/helpers/RouteGenerator";
import { useEffect, useMemo } from "react";
import { useUrlSearchParams } from "use-url-search-params";

import { useAuthUser } from "@crema/hooks/AuthHooks";
import { useAppSettingContext } from "contexts/appSetting";
import { useRoutes } from "react-router-dom";
import {
  anonymousStructure,
  authorizedStructure,
  publicStructure,
} from "../AppRoutes";

import { SYSTEM_CONSTANTS } from "constants/AppConstant";
import AppSettings from "constants/AppEnum";

const AppLayout = () => {
  // user got after loging in
  const { user, isAuthenticated, permissions } = useAuthUser();
  const { appSetting, updateAppSetting } = useAppSettingContext();
  const [params] = useUrlSearchParams();

  useEffect(() => {
    // If the user role is changed then it will update appropriate ui layout and theme
    updateAppSetting(
      AppSettings[user?.layout_type_name || SYSTEM_CONSTANTS.LAYOUT_TYPE.PUBLIC]
    );
    localStorage.setItem(
      "userRole",
      user?.layout_type_name || SYSTEM_CONSTANTS.LAYOUT_TYPE.PUBLIC
    );
  }, [user?.role_name]);

  const AppLayout = Layouts[appSetting.navStyle];
  const initURL = params?.redirect ? params?.redirect : appSetting?.initialURL;
  const loginUrl = `${appSetting?.loginURL}?redirect=${window.location.pathname}`;

  // Generates dynamic routes on the basis of the role
  const { dynamicRoutes, dynamicRouteConfig } = useMemo(
    () =>
      generateDynamicRoutesWithRoleAndPermissions({
        isAuthenticated: isAuthenticated,
        userRole: user?.role_name,
        permissions: permissions || {},
        anonymousStructure: anonymousStructure(initURL),
        authorizedStructure: authorizedStructure(loginUrl, user?.role_name),
        publicStructure: publicStructure(initURL),
      }),
    [user?.role_name, initURL, loginUrl]
  );

  const routes = useRoutes(dynamicRoutes);

  return (
    <AppLayout
      routes={routes}
      dynamicRoutes={dynamicRoutes}
      routesConfig={dynamicRouteConfig}
    />
  );
};

export default AppLayout;
