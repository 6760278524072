import React, { useEffect } from "react";

// formik components
import { Formik } from "formik";

// custom component
import CommonFilterForm from "./CommonFilterForm";

// react icons
import { useAuthUser } from "@crema/hooks/AuthHooks";
import { useDefaultFilterContext } from "contexts/defaultFilterContext";
import { useAcademicYearContext } from "contexts/master/academic/academicYearContext";

const CommonFilter = ({
  gridItemSize,
  selectSize,
  handleCommonFilterClose,
}) => {
  const { user, isAuthenticated } = useAuthUser();

  const academicYear = useAcademicYearContext();
  const initialValues = {
    branch_id: user?.default_branch_id ? user?.default_branch_id : "",
    academic_year_id: user?.default_academic_year_id
      ? user?.default_academic_year_id
      : "",
  };

  const { updateDefaultFilters } = useDefaultFilterContext();

  useEffect(() => {
    if (isAuthenticated) {
      academicYear?.getList(null);
    }
  }, [isAuthenticated]);

  return (
    <Formik initialValues={initialValues}>
      {({ values, initialValues, setFieldValue }) => (
        <CommonFilterForm
          values={values}
          initialValues={initialValues}
          academicYear={academicYear}
          gridItemSize={gridItemSize}
          updateDefaultFilters={updateDefaultFilters}
          selectSize={selectSize}
          handleCommonFilterClose={handleCommonFilterClose}
          setFieldValue={setFieldValue}
          user={user}
        />
      )}
    </Formik>
  );
};

export default React.memo(CommonFilter);
