import { USER_ROLE } from "constants/AppConstant";
import React from "react";
import { ImBooks } from "react-icons/im";

const AdmissionStudentStudyMaterial = React.lazy(() =>
  import("modules/student/admission/admissionStudentStudyMaterial")
);

const routes = {
  permittedRole: [USER_ROLE.STUDENT],
  title: "Study Materials",
  path: ["/student/studymaterials"],
  element: <AdmissionStudentStudyMaterial />,
  type: "item",
  icon: <ImBooks />,
};

export default routes;
