import { Navigate } from "react-router-dom";

// import { USER_ROLE } from 'constants/AppConstant';

import { USER_ROLE } from "constants/AppConstant";
import PermissionHelper from "helpers/PermissionHelper";
import { checkPermission } from "./RouteHelper";

/**
 * @param {Object} structure - The passed object that defines the routes.
 * @param {boolean} structure.isAuthenticated - [Required] in order to differentiate between LoggedIn/Loggedout users
 * @param {string} structure.userRole - [Optional] in order to differentiate between admin and normal users
 * @param {object} [structure.anonymousStructure] - it's an object that has only [ routes ] array, [ these routes available for All personas ]
 * @param {object} [structure.authorizedStructure] - it's an object that has [ fallbackPath: {string}, routes: {array} ], fallbackPath: is used for redirect when a logged [in] user tried to access unAuthorized route, routes: only The Logged [in] Routes Available
 * @param {object} [structure.publicStructure] - it's an object that has [ fallbackPath: {string}, routes: {array} ], fallbackPath: is used for redirect when a logged [out] user tried to access route that requires [Authorization] , routes: only The Logged [out] Routes Available
 * @param {component} [structure.component fallbackComponent] - in order to redirect in all cases if the route doesn't match.
 * @param {unAuthorizedComponent} [structure.unAuthorizedComponent] - in order to show not permitted route.
 * @returns {Array} - [Array of routes]
 */

const generateDynamicRoutesWithRoleAndPermissions = (structure) => {
  const {
    isAuthenticated = false,
    anonymousStructure = {},
    authorizedStructure = {},
    publicStructure = {},
    userRole = USER_ROLE.PUBLIC,
    permissions,
  } = structure || {};

  let dynamicRoutes = [];
  const dynamicRouteConfig = [];

  if (anonymousStructure) {
    const { generatedRoutes, routeConfig } = routesGenerator(
      isAuthenticated,
      anonymousStructure,
      "anonymous"
    );

    dynamicRouteConfig.push(...routeConfig);
    dynamicRoutes.push(...generatedRoutes);
  }

  if (authorizedStructure) {
    const { generatedRoutes, routeConfig } = routesGenerator(
      isAuthenticated,
      authorizedStructure,
      "authorized",
      userRole ? userRole : USER_ROLE.PUBLIC,
      permissions
    );

    dynamicRouteConfig.push(...routeConfig);
    dynamicRoutes.push(...generatedRoutes);
  }

  if (publicStructure) {
    const { generatedRoutes, routeConfig } = routesGenerator(
      isAuthenticated,
      publicStructure,
      "public",
      userRole
    );

    dynamicRouteConfig.push(...routeConfig);
    dynamicRoutes.push(...generatedRoutes);
  }
  return { dynamicRoutes, dynamicRouteConfig };
};

/**
 * path: string
 * component: React.Component
 * routeProps: Object -----> To override route props
 * userRole: string -----> To override route props
 * redirectPath: String ----> To redirect to specific location
 * showRouteIf: to override when to show the component or when to [ Navigate ]
 */
const routesGenerator = (
  isAuthenticated = false,
  routeSet = {},
  type = "anonymous",
  userRole = USER_ROLE.PUBLIC,
  permissions = {}
) => {
  const generatedRoutes = [];
  const { fallbackPath = "" } = routeSet || {};

  const isAnonymous = type === "anonymous";
  const isAuthorized = type === "authorized";

  if (routeSet?.routes) {
    const { routes } = routeSet;
    if (Array.isArray(routes) && routes.length > 0) {
      LoopThroughRoutes(
        routes,
        generatedRoutes,
        fallbackPath,
        isAnonymous,
        isAuthorized,
        isAuthenticated,
        userRole,
        routeSet.unAuthorizedComponent,
        permissions
      );
    }
  } else {
    console.log(`[routes] prop can't be found in ${type}Structure Object`);
  }

  return { generatedRoutes, routeConfig: routeSet.routes };
};

export default generateDynamicRoutesWithRoleAndPermissions;

const LoopThroughRoutes = (
  routes,
  generatedRoutes,
  fallbackPath,
  isAnonymous,
  isAuthorized,
  isAuthenticated,
  userRole,
  unAuthorizedComponent,
  permissions = {}
) => {
  routes.forEach((route /* index */) => {
    if (route?.children) {
      if (Array.isArray(route?.children) && route?.children.length > 0) {
        // This will check the permission and assign to the route
        route = PermissionHelper(userRole, permissions, route);

        LoopThroughRoutes(
          route?.children,
          generatedRoutes,
          fallbackPath,
          isAnonymous,
          isAuthorized,
          isAuthenticated,
          userRole,
          unAuthorizedComponent,
          permissions
        );
      }
    } else {
      const {
        path = "",
        permittedRole = USER_ROLE.SUPER_ADMIN,
        redirectPath = "",
        showRouteIf = true,
      } = route || {};

      // Show Route only [ in The list ] if this prop is true
      if (showRouteIf) {
        // check the mandatory props for a routes
        if (!path) {
          console.log(
            `A [route] is skipped because one of the following, No valid [path] prop provided for the route`,
            isAuthenticated
          );
        } else {
          if (isAnonymous) {
            return generatedRoutes.push(route);
          }

          if (isAuthorized) {
            const renderCondition = isAuthorized
              ? isAuthenticated
              : !isAuthenticated;

            if (Array.isArray(route.path)) {
              route.path.map((path) => {
                // This will check the permission and assign to the route
                route = PermissionHelper(userRole, permissions, route);
                generatedRoutes.push(
                  renderCondition
                    ? userRole === USER_ROLE.SUPER_ADMIN
                      ? {
                          title: route.title,
                          element: route.element,
                          path: path,
                          permittedRole: route.permittedRole,
                          // permittedRole: userRole, // previous line -> route.permittedRole,
                        }
                      : Object.keys(permissions).includes(path) // previous method-> checkPermission(permittedRole, userRole)
                      ? {
                          title: route.title,
                          element: route.element,
                          path: path,
                          permittedRole: userRole, // previous line -> route.permittedRole,
                        }
                      : {
                          title: route.title,
                          path: path,
                          element: unAuthorizedComponent,
                        }
                    : {
                        title: route.title,
                        path: path,
                        element: (
                          <Navigate to={redirectPath || fallbackPath} replace />
                        ),
                      }
                );
              });
            } else {
              generatedRoutes.push(
                renderCondition
                  ? checkPermission(permittedRole, userRole)
                    ? route
                    : {
                        title: route.title,
                        path: route.path,
                        element: unAuthorizedComponent,
                      }
                  : {
                      title: route.title,
                      path: route.path,
                      element: (
                        <Navigate to={redirectPath || fallbackPath} replace />
                      ),
                    }
              );
            }
            return generatedRoutes;
          }

          const renderCondition = isAuthorized
            ? isAuthenticated
            : !isAuthenticated;
          if (Array.isArray(route.path)) {
            route = PermissionHelper(userRole, permissions, route);
            route.path.map((path) => {
              generatedRoutes.push(
                renderCondition
                  ? {
                      title: route.title,

                      element: route.element,
                      path: path,
                      permittedRole: route.permittedRole,
                    }
                  : {
                      title: route.title,

                      path: path,
                      element: (
                        <Navigate to={redirectPath || fallbackPath} replace />
                      ),
                    }
              );
            });
          } else {
            generatedRoutes.push(
              renderCondition
                ? route
                : {
                    title: route.title,

                    path: route.path,
                    element: (
                      <Navigate to={redirectPath || fallbackPath} replace />
                    ),
                  }
            );
          }
          return generatedRoutes;
        }
      }
    }
  });
};
