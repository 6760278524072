// React imports
import { createContext, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state
import reducer from "reducers/master/academic/feesTypesReducer";

// context to be used in the app
const FeesTypeContext = createContext();

// hook to use the context states in the ui
const useFeesTypeContext = () => {
  return useContext(FeesTypeContext);
};
export { useFeesTypeContext };

// Initial State
const initialState = {
  data: [],
  count: 0,
};

// provider of the context
const FeesTypeProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName = API_NAMES.MASTER.ACADEMIC.FEES_TYPE;

  // Fetches whole records from DB
  const getList = (params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  };

  // Fetches single record in records of DB
  const getItem = (_id) => {
    GetAPI(apiName, dispatch, _id);
  };

  // Update single record in records of DB
  const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  };

  // Add new record in records of DB
  const createItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    CreateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  };

  // Delete on or may records from records in DB
  const deleteItems = (params, _ids) => {
    DeleteAPI(apiName, dispatch, params, _ids);
  };

  return (
    <FeesTypeContext.Provider
      value={{
        data: state.data,
        count: state.count,
        getList,
        getItem,
        updateItem,
        createItem,
        deleteItems,
      }}
    >
      {children}
    </FeesTypeContext.Provider>
  );
};

export default FeesTypeProvider;
