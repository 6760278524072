import { IconButton, alpha } from "@mui/material";
import AppTooltip from "../AppTooltip";

const AppIconButton = ({
  buttonProps = {},
  icon,
  sx = {},
  title,
  placement = "top",
}) => {
  return (
    <AppTooltip title={title} placement={placement}>
      <IconButton sx={{ mx: 1, ...headerIconStyles, ...sx }} {...buttonProps}>
        {icon}
      </IconButton>
    </AppTooltip>
  );
};

export default AppIconButton;

const headerIconStyles = {
  borderRadius: "50%",
  width: 40,
  height: 40,
  color: (theme) => theme.palette.text.secondary,
  backgroundColor: (theme) => theme.palette.background.default,
  border: 1,
  borderColor: "transparent",
  "&:hover, &:focus": {
    color: (theme) => theme.palette.text.primary,
    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.9),
    borderColor: (theme) => alpha(theme.palette.text.secondary, 0.25),
  },
};
