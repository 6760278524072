import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const EntranceResultAttendance = React.lazy(() =>
  import("modules/student/entrance/entranceStudentResultAttendance")
);

const routes = {
  title: "Result & Attendance",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/entrance/attendanceresults"],
  element: <EntranceResultAttendance />,
  type: "item",
};

export default routes;
