import React from "react";
import PropsTypes from "prop-types";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material";
import { useAppSettingContext } from "contexts/appSetting";
import { CSS_CONSTANTS } from "constants/AppConstant";

const SidebarBgWrapper = ({ children }) => {
  const { appSetting } = useAppSettingContext();
  const { sidebarBgColor, sidebarTextColor } = appSetting.sidebar;
  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        backgroundColor: sidebarBgColor,
        color: sidebarTextColor,

        "& > *": {
          position: "relative",
          // zIndex: 3,
          zIndex: CSS_CONSTANTS.Z_INDEX.SIDEBAR_BG_WRAPPER,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default SidebarBgWrapper;

SidebarBgWrapper.propTypes = {
  children: PropsTypes.node,
  sidebarBgColor: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number]),
};
