import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const Brochure = React.lazy(() =>
  import("modules/public/admission/brochure/index")
);

const routes = {
  element: <Brochure />,
  title: "Brochure",
  type: "item",
  path: ["/admission/brochure"],
  permittedRole: [USER_ROLE.PUBLIC],
};

export default routes;
