import AcademicYearProvider from "contexts/master/academic/academicYearContext";
import BranchProvider from "contexts/master/academic/branchContext";
import StandardProvider from "contexts/master/academic/standardContext";
import SubjectProvider from "contexts/master/academic/subjectContext";
import PropTypes from "prop-types";
import AcademicAchievementProvider from "./academicAchievementContext";
import AdmissionModeProvider from "./admissionModeContext";
import AdmissionSourceProvider from "./admissionSourceContext";
import BranchCityProvider from "./branchCityContext";
import BuildingProvider from "./buildingContext";
import ClassroomProvider from "./classroomContext";
import CompetitiveExamProvider from "./competitiveExamContext";
import DivisionProvider from "./divisionContext";
import DivisionGroupProvider from "./divisionGroupContext";
import DivisionStandardBranchAcademicYearMappingProvider from "./divisionStandardBranchAcademicYearMappingContext";
import DivisionSubjectMappingProvider from "./divisionSubjectMappingContext";
import EntranceTestScheduleProvider from "./entranceTestScheduleContext";
import ScholarshipProvider from "./entrranceScholarshipContext";
import FeesConcessionProvider from "./feesConcessionContext";
import FeesConcessionGroupProvider from "./feesConcessionGroupContext";
import FeesConcessionTypeProvider from "./feesConcessionTypeContext";
import FeesStructureInstallmentProvider from "./feesInstallmentContext";
import FeesInstallmentTypeProvider from "./feesInstallmentTypeContext";
import FeesStructureProvider from "./feesStructureContext";
import FeesTypeProvider from "./feesTypeContext";
import IdentityCardProvider from "./identityCardContext";
import LearningModeProvider from "./learningModeContext";
import PaymentMethodProvider from "./paymentMethodContext";
import ReceiptBookTypeProvider from "./receiptBookTypeContext";
import ReceiptProvider from "./receiptContext";
import SchoolBoardProvider from "./schoolBoardContext";
import SchoolMediumProvider from "./schoolMediumContext";
import StandardBranchAcademicYearMappingProvider from "./standardBranchAcademicYearMappingContext";
import TimeSlotProvider from "./timeSlotContext";
import TimeTableProvider from "./timeTableContext";
import VirtualDivisionProvider from "./virtualDivisionContext";
import WorkingLoadSummaryProvider from "./workingLoadSummaryContext";

const AcademicMasterProvider = ({ children }) => {
  return (
    <WorkingLoadSummaryProvider>
      <TimeTableProvider>
        <TimeSlotProvider>
          <DivisionSubjectMappingProvider>
            <DivisionGroupProvider>
              <BuildingProvider>
                <IdentityCardProvider>
                  <ClassroomProvider>
                    <FeesConcessionGroupProvider>
                      <FeesConcessionProvider>
                        <FeesConcessionTypeProvider>
                          <AdmissionModeProvider>
                            <FeesInstallmentTypeProvider>
                              <ReceiptBookTypeProvider>
                                <AdmissionSourceProvider>
                                  <PaymentMethodProvider>
                                    <FeesTypeProvider>
                                      <ReceiptProvider>
                                        <BranchCityProvider>
                                          <FeesStructureProvider>
                                            <DivisionStandardBranchAcademicYearMappingProvider>
                                              <StandardBranchAcademicYearMappingProvider>
                                                <EntranceTestScheduleProvider>
                                                  <FeesStructureInstallmentProvider>
                                                    <AcademicAchievementProvider>
                                                      <LearningModeProvider>
                                                        <SchoolMediumProvider>
                                                          <SchoolBoardProvider>
                                                            <CompetitiveExamProvider>
                                                              <VirtualDivisionProvider>
                                                                <DivisionProvider>
                                                                  <ScholarshipProvider>
                                                                    <SubjectProvider>
                                                                      <BranchProvider>
                                                                        <StandardProvider>
                                                                          <AcademicYearProvider>
                                                                            {
                                                                              children
                                                                            }
                                                                          </AcademicYearProvider>
                                                                        </StandardProvider>
                                                                      </BranchProvider>
                                                                    </SubjectProvider>
                                                                  </ScholarshipProvider>
                                                                </DivisionProvider>
                                                              </VirtualDivisionProvider>
                                                            </CompetitiveExamProvider>
                                                          </SchoolBoardProvider>
                                                        </SchoolMediumProvider>
                                                      </LearningModeProvider>
                                                    </AcademicAchievementProvider>
                                                  </FeesStructureInstallmentProvider>
                                                </EntranceTestScheduleProvider>
                                              </StandardBranchAcademicYearMappingProvider>
                                            </DivisionStandardBranchAcademicYearMappingProvider>
                                          </FeesStructureProvider>
                                        </BranchCityProvider>
                                      </ReceiptProvider>
                                    </FeesTypeProvider>
                                  </PaymentMethodProvider>
                                </AdmissionSourceProvider>
                              </ReceiptBookTypeProvider>
                            </FeesInstallmentTypeProvider>
                          </AdmissionModeProvider>
                        </FeesConcessionTypeProvider>
                      </FeesConcessionProvider>
                    </FeesConcessionGroupProvider>
                  </ClassroomProvider>
                </IdentityCardProvider>
              </BuildingProvider>
            </DivisionGroupProvider>
          </DivisionSubjectMappingProvider>
        </TimeSlotProvider>
      </TimeTableProvider>
    </WorkingLoadSummaryProvider>
  );
};

export default AcademicMasterProvider;

AcademicMasterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
