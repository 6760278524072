import React from "react";
import { USER_ROLE } from "constants/AppConstant";

const EntranceRegistrationList = React.lazy(
  () => import("modules/student/entrance/entranceStudentRegistrationList")
);

const routes = {
  title: "Applications",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/entrance/applications"],
  element: <EntranceRegistrationList />,
  type: "item",
};

export default routes;
