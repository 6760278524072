import React from "react";
import { USER_ROLE } from "constants/AppConstant";
import { IoNewspaperSharp } from "react-icons/io5";
import entranceStudentRegisterRoutes from "./entranceStudentRegisterRoutes";
import entranceStudentProceedToLoginRoutes from "./entranceStudentProceedToLoginRoutes";
import loginRoutes from "../../loginRoutes";

const routes = {
  title: "NSAT ",
  permittedRole: [USER_ROLE.PUBLIC],
  type: "group",
  icon: <IoNewspaperSharp />,
  path: ["/nsat"],
  children: [
    entranceStudentRegisterRoutes,
    loginRoutes,
    entranceStudentProceedToLoginRoutes,
  ],
};

export default routes;
