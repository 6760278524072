// ForJWT Auth
// import { getUserFromJwtAuth } from "@crema/helpers/AuthHelper";
import {
  useJWTAuth,
  useJWTAuthActions,
} from "@crema/services/auth/jwt-auth/JWTAuthProvider";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading, permissions } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    permissions,
    user: user,
  };
};

export const useAuthMethod = () => {
  const jwtActions =
    useJWTAuthActions();

  return jwtActions;
};
