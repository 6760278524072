import PropTypes from "prop-types";
import CompetitiveTagProvider from "./competitiveTagContext";
import DifficultyLevelProvider from "./difficultyLevelContext";
import DivisionGroupWiseTopicAllocationProvider from "./divisionGroupWiseTopicAllocationContext";
import DivisionSubjectTopicProvider from "./divisionSubjectTopicContext";
import QuestionProvider from "./questionContext";
import QuestionTypeProvider from "./questionTypeContext";
import SourceTagProvider from "./sourceTagContext";
import SubjectProvider from "./subjectContext";
import SubjectTopicProvider from "./subjectTopicContext";

const QuestionBankProvider = ({ children }) => {
  return (
    <QuestionProvider>
      <DivisionGroupWiseTopicAllocationProvider>
        <DivisionSubjectTopicProvider>
          <CompetitiveTagProvider>
            <DifficultyLevelProvider>
              <QuestionTypeProvider>
                <SourceTagProvider>
                  <SubjectProvider>
                    <SubjectTopicProvider>{children}</SubjectTopicProvider>
                  </SubjectProvider>
                </SourceTagProvider>
              </QuestionTypeProvider>
            </DifficultyLevelProvider>
          </CompetitiveTagProvider>
        </DivisionSubjectTopicProvider>
      </DivisionGroupWiseTopicAllocationProvider>
    </QuestionProvider>
  );
};

export default QuestionBankProvider;

QuestionBankProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
