import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { buttonStyles } from "modules/student/entrance/entranceStudentRegistrationList/Header/HeaderForm";
import PropTypes from "prop-types";
import { useState } from "react";
import AppIconButton from "../AppIconButton";
import AppNotificationContent from "./AppNotificationContent";

const AppNotifications = ({
  drawerPosition,
  tooltipPosition,
  isMenu,
  sxNotificationContentStyle,
}) => {
  const [showNotification, setShowNotification] = useState(false);

  return (
    <>
      {isMenu ? (
        <Box
          component="span"
          onClick={() => setShowNotification(true)}
        >
          Message
        </Box>
      ) : (
        <AppIconButton
          title="Notification"
          placement={tooltipPosition}
          icon={<NotificationsNoneIcon />}
          sx={buttonStyles}
          buttonProps={{
            onClick: () => setShowNotification(true),
          }}
        />
      )}

      <Drawer
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <AppNotificationContent
          sxStyle={sxNotificationContentStyle}
          onClose={() => setShowNotification(false)}
        />
      </Drawer>
    </>
  );
};

export default AppNotifications;

AppNotifications.defaultProps = {
  drawerPosition: "right",
  tooltipPosition: "bottom",
};

AppNotifications.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
