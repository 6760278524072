import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import clsx from "clsx";
import PropTypes from "prop-types";
import AppScrollbar from "../../../AppScrollbar";
import MainSidebar from "../../components/MainSidebar";
import VerticalNav from "../../components/VerticalNav";
import UserInfo from "../UserInfo";
import SidebarWrapper from "./SidebarWrapper";

const AppSidebar = (props) => {
  return (
    <>
      <Hidden xlUp>
        <Drawer
          anchor={props.position}
          open={props.isNavCollapsed}
          onClose={props.toggleNavCollapsed}
          classes={{
            root: clsx(props.variant),
            paper: clsx(props.variant),
          }}
          style={{ position: "absolute" }}
        >
          <SidebarWrapper className="mini-toggle-sidebar">
            <MainSidebar>
              <UserInfo color={props?.sidebarTextColor} />
              <AppScrollbar
                sx={{
                  py: 2,
                  height: "calc(100vh - 70px) !important",
                }}
              >
                <VerticalNav routesConfig={props.routesConfig} index={1} />
              </AppScrollbar>
            </MainSidebar>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <SidebarWrapper className="mini-toggle-sidebar">
          <MainSidebar>
            <UserInfo color={props?.sidebarTextColor} />
            <AppScrollbar
              sx={{
                py: 2,
                height: "calc(100vh - 70px) !important",
              }}
            >
              <VerticalNav routesConfig={props.routesConfig} />
            </AppScrollbar>
          </MainSidebar>
        </SidebarWrapper>
      </Hidden>
    </>
  );
};
export default AppSidebar;

AppSidebar.defaultProps = {
  variant: "",
  position: "left",
};

AppSidebar.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
  toggleNavCollapsed: PropTypes.func,
  isNavCollapsed: PropTypes.bool,
  routes: PropTypes.object,
  routesConfig: PropTypes.array,
};
