import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const ZoomSetup = React.lazy(() =>
    import("modules/master/communication/zoomSetup")
);

const routes = {
    element: <ZoomSetup />,
    title: "Zoom Setup",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ["/zoom/accounts"]
};

export default routes;
