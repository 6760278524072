import { Box } from "@mui/material";
import { CSS_CONSTANTS } from "constants/AppConstant";
import PropsTypes from "prop-types";

const SidebarWrapper = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        width: 280,
        display: "flex",
        flexDirection: "column",
        transition: "all 0.5s ease",
        position: { xs: "relative", lg: "fixed" },
        top: 0,
        left: 0,
        // zIndex: 1101,
        zIndex: CSS_CONSTANTS.Z_INDEX.SIDEBAR_WRAPPER,
        "& .app-sidebar": {
          position: "relative",
          top: "auto",
          left: "auto",
          width: "100%",
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default SidebarWrapper;

SidebarWrapper.propTypes = {
  children: PropsTypes.node,
};
