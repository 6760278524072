// dispatch types is constant object for dispatch types in reducer
import { DISPATCH_TYPES } from 'constants/ActionTypes';
import { STUDENT_CONSTANT } from 'constants/AppConstant';

const reducer = (state, action) => {
    switch (action.type) {
        // It will set all fetched records in state
        case DISPATCH_TYPES.GET_ALL:
            const attendance_data = {}
            for (const data of action.payload.data) {
                attendance_data[data.student_admission_id] = {
                    attendance_status: data?.attendance_status === STUDENT_CONSTANT.ATTENDANCE.PENDING ? STUDENT_CONSTANT.ATTENDANCE.ABSENT : data?.attendance_status,
                    student_attendance_log_id: data?.student_attendance_log_id,
                }
            }
            return {
                ...state,
                attendance_data: attendance_data,
                data: action.payload.data,
                count: action.payload.count,
            };

        // It will set all fetched records in state
        case DISPATCH_TYPES.PRINT:
            return {
                ...state,
                printList: action.payload.data,
            };

        // If no case matches then return same state
        default:
            return state;
    }
};

export default reducer;
