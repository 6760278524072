// React imports
import { createContext, useCallback, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state
import { DISPATCH_TYPES } from "constants/ActionTypes";
import reducer from "reducers/admin/dashboard/paymentTransactionSummaryByDivisionReducer";

// context to be used in the app
const PaymentTransactionSummaryByDivisionContext = createContext();

// hook to use the context states in the ui
const usePaymentTransactionSummaryByDivisionContext = () => {
  return useContext(PaymentTransactionSummaryByDivisionContext);
};
export { usePaymentTransactionSummaryByDivisionContext };

// Initial State
const initialState = {
  data: [],
  count: 0,
  printList: {},
};

// provider of the context
const PaymentTransactionSummaryByDivisionProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName = API_NAMES.ADMIN.DASHBOARD.PAYMENT_TRANSATION_SUMMARY_DIVISION;

  // Fetches whole records from DB
  const getList = useCallback((params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  }, []);

  const getPrintList = useCallback((params, callbackFunction) => {
    return GetListAPI(
      apiName,
      dispatch,
      params,
      callbackFunction,
      null,
      DISPATCH_TYPES.PRINT
    );
  }, []);

  return (
    <PaymentTransactionSummaryByDivisionContext.Provider
      value={{
        data: state.data,
        count: state.count,
        printList: state.printList,
        getPrintList,
        getList,
      }}
    >
      {children}
    </PaymentTransactionSummaryByDivisionContext.Provider>
  );
};

export default PaymentTransactionSummaryByDivisionProvider;
