import React from "react";
import { USER_ROLE } from "constants/AppConstant";
import { MdOutlineHandshake } from "react-icons/md";

const About = React.lazy(() => import("modules/public/about"));

const routes = {
  element: <About />,
  title: "About Us",
  type: "item",
  permittedRole: [USER_ROLE.PUBLIC],
  path: ["/about"],
  icon: <MdOutlineHandshake />,
};

export default routes;
