import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const UserRegistrationList = React.lazy(() =>
  import("modules/user/userRegistrationList")
);

const routes = {
  title: "List",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/user/registrations"],
  element: <UserRegistrationList />,
  type: "item",
};

export default routes;
