// React Utilities
import PropTypes from "prop-types";
import React, { useCallback } from "react";

// Crema Componenets
import AppDialog from "@crema/components/AppDialog";

// MUI Components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// MUI icons
import PaidIcon from "@mui/icons-material/Paid";
import { Button, Typography } from "@mui/material";
import { CSS_CONSTANTS } from "constants/AppConstant";

const PaymentTerms = ({ isPaymentTermsOpen, setIsPaymentTermsOpen }) => {
  // It will handle the click on the close button of dialog box
  const handleClose = useCallback(
    () => setIsPaymentTermsOpen(false),
    [isPaymentTermsOpen]
  );

  return (
    <AppDialog
      dividers
      dialogIcon={<PaidIcon />}
      title="Payment Terms"
      onClose={handleClose} // It will close the dialog box
      open={isPaymentTermsOpen}
      sxStyle={{
        "& .MuiDialog-paper": { maxWidth: CSS_CONSTANTS.FORM_SIZE.BIG },
      }}
    >
      {paymentTermsInfoPara[0]?.map((item, index) => (
        <Typography
          key={index}
          component="p"
          sx={{ my: 3, textAlign: "justify" }}
        >
          {item}
        </Typography>
      ))}

      <List
        component="ul"
        sx={{
          listStyleType: "disc",
          pl: 10,
          "& .MuiListItem-root": {
            display: "list-item",
          },
          li: {
            p: 0,
          },
        }}
      >
        {paymentTermsInfoList[0]?.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              sx={{ textAlign: "justify" }}
              primary={item}
            ></ListItemText>
          </ListItem>
        ))}
      </List>

      {paymentTermsInfoPara[1]?.map((item, index) => (
        <Typography
          key={index}
          component="p"
          sx={{ my: 3, textAlign: "justify" }}
        >
          {item}
        </Typography>
      ))}

      <List
        component="ul"
        sx={{
          listStyleType: "disc",
          pl: 10,
          "& .MuiListItem-root": {
            display: "list-item",
          },
          li: {
            p: 0,
          },
        }}
      >
        {paymentTermsInfoList[1]?.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              sx={{ textAlign: "justify" }}
              primary={item}
            ></ListItemText>
          </ListItem>
        ))}
      </List>

      {paymentTermsInfoPara[2]?.map((item, index) => (
        <Typography
          key={index}
          component="p"
          sx={{ my: 3, textAlign: "justify" }}
        >
          {item}
        </Typography>
      ))}
      <Button
        variant="contained"
        sx={{
          float: "right",
        }}
        onClick={handleClose}
      >
        Close
      </Button>
    </AppDialog>
  );
};

export default React.memo(PaymentTerms);

PaymentTerms.propTypes = {
  isPaymentTermsOpen: PropTypes.bool,
  setIsPaymentTermsOpen: PropTypes.func,
};

const paymentTermsInfoPara = [
  [
    <>Date: [10/06/2023]</>,
    <>
      These Payment Terms are to be read in conjunction with the Terms and
      Conditions of Use issued by Netizens Foundation School Any terms
      capitalized but not defined herein shall have the meaning provided to such
      terms in the Terms and Conditions of Use.
    </>,
    <>
      These Payment Terms apply to purchases made via our website. For users of
      our Services on our app offerings on the App Store or the Play Store,
      please visit our Website to manage your account. You may be additionally
      subject to the terms and conditions of the App Store or Play Store, as the
      case may be.
    </>,
    <>
      By clicking the link you will be redirected to the website of the third
      party for the purpose of online course fee payment. All payments in
      respect of the Services through the Platform shall be made to Netizens
      Foundation School through such third party website. The third party
      website is not owned or controlled by Netizens Foundation School and
      contents thereof are not sponsored, endorsed or approved by the institute.
      Netizens Foundation School does not vouch or guarantee or take any
      responsibility for any of the contents of the said website including
      transactions, product, services or other items offered through the
      website. You will access this site solely for the purpose of online
      payment of course fee and you acknowledge that any reliance on any
      opinion, advice, statement, memorandum, or information available on the
      site shall be at your sole risk and consequences.
    </>,
    <>
      The modes of payments available to you are, amongst others, credit card,
      debit card, net banking, UPI. The information on security of these payment
      methods, along with the information on any Fee or charges payable by you,
      charge back options (if any), will be provided to you by the third-party
      payment gateway provider. The contact details of the third-party payment
      gateway provider are - (a) HDFC Bank Limited, headquartered at 1st Floor,
      C.S.No.6/242, Senapati Bapat Marg, Lower Parel, Mumbai 400013,
      Maharashtra, India, email: support@hdfcbank.com; and (b) ICICI Bank,
      headquartered at ICICI Bank Towers, Bandra-Kurla Complex, Mumbai 400051,
      Phone: 1800 1080.
    </>,
    <>
      Netizens Foundation School and its branches, employees, officers,
      directors and other associates shall not be liable for any loss, claim or
      damage whatsoever including
    </>,
  ],
  [
    <>
      Netizens Foundation School is not directly involved in the online payment
      but through service provider. Thus, we shall not be responsible in any
      case until the full dues payable by student or parent is credited in to
      the bank account of the institute.
      <br />
      Upon completing an Online Service, you will be presented with a
      confirmation screen verifying the transaction details you wish to process.
      It is your responsibility to verify that all transaction information and
      other details are correct. The transaction shall be deemed binding at the
      time the confirmation screen is displayed. You should print the
      transaction confirmation for future reference and your files. Netizens
      Foundation School shall have no accountability for transactions which are
      incorrect as a result of inaccurate data entry in the course of providing
      Online Services or for loss of data or information caused by factors
      outside of institute&apos;s control.
      <br />
      In order to make payments online, you undertake to use a valid payment
      card or bank details or third-party wallets or details required for any
      other mode of payment (Payment Details) with the authority to use the
      same, to have sufficient funds or credit available to complete the payment
      on the Website in good standing. These Payment Details will be provided by
      you to our third-party payment gateway providers and will be governed by
      such payment gateway providers&apos; terms and policies. By providing the
      Payment Details, you represent, warrant, and covenant that:
    </>,
  ],
  [
    <>
      The payment receipt for completion of a transaction shall be made
      available to you on the Platform and also sent to your registered email
      address.
      <br />
      We reserve the right to amend the Payment Terms at any time without prior
      notice, which shall be updated on this page, regularly, so that you are
      always aware of the latest developments in the Platform. The date at the
      top of this page indicates when these Payment Terms were last updated. In
      case of any changes or updates, we shall endeavor to notify the User of
      such changes at the earliest, and notify about all material changes. By
      clicking &lsquo;I Agree&apos; or &lsquo;I Accept&apos;, or using the
      Platform or availing Services, you agree to be bound by these changes.
    </>,
  ],
];

const paymentTermsInfoList = [
  [
    <>Any deficiency in the service of such third party websites;</>,
    <>
      Any consequences of error or failure of internet connection, equipment,
      hardware or software used to access the third party website through this
      link;
    </>,
    <>Slowdown or breakdown of third party website for any reason;</>,
    <>
      Any interception, &quot;hacking&quot; or other unauthorized access to
      information provided by a user of this service;
    </>,
    <>
      Liability for a payment not reaching the correct institute account due to
      you quoting an incorrect account number or incorrect personal details
    </>,
    <>
      Liability if payment is refused or declined by the credit/debit card
      supplier for any reason, or the third-party wallet does not contain
      sufficient funds to complete the transaction. If the card supplier
      declines payment, the institute is under no obligation to bring this fact
      to your attention. You should check with your bank/credit/debit card
      supplier that payment has been deducted from your account.
    </>,
    <>
      Circumstances beyond Netizens Foundation School&apos;s control (such as,
      but not limited to, power outages, interruptions of cellular service, or
      any other interferences from an outside force) prevent the execution of
      the transaction
    </>,
  ],
  [
    <>You are legally authorized to provide such Payment Details;</>,
    <>
      You are legally authorized to perform payments using such Payment Details;
      and
    </>,
    <>
      Such action does not violate the terms and conditions applicable to your
      use of such Payment Details or applicable law.
    </>,
  ],
];
