import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const FoundationReceipt = React.lazy(() =>
  import("modules/student/receipt/foundation")
);

const routes = {
  element: <FoundationReceipt />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/foundation/receipt"],
};

export default routes;
