import React from "react";
import { USER_ROLE } from "constants/AppConstant";

const Enquiry = React.lazy(() => import("modules/public/enquiry"));

const routes = {
  element: <Enquiry />,
  permittedRole: [USER_ROLE.PUBLIC],
  path: ["/enquiry"],
};

export default routes;
