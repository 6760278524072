import React from "react";
import { USER_ROLE } from "constants/AppConstant";
import { RiLiveFill } from "react-icons/ri";

const AdmissionStudentLiveClass = React.lazy(() =>
  import("modules/student/admission/admissionStudentLiveClass")
);

const routes = {
  permittedRole: [USER_ROLE.STUDENT],
  title: "Live Classes",
  path: ["/student/liveclasses"],
  element: <AdmissionStudentLiveClass />,
  type: "item",
  icon: <RiLiveFill />,
};

export default routes;
