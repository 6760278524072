import React from "react";
import PropTypes from "prop-types";
import DashboardContextProvider from "./dashboard";

const AdminContextProvider = ({ children }) => {
  return <DashboardContextProvider>{children}</DashboardContextProvider>;
};

export default AdminContextProvider;

AdminContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
