import PropTypes from "prop-types";
import AttendanceDeviceConfigurationProvider from "./attendanceDeviceConfigurationContext";
import AttendanceDeviceUserUploadProvider from "./attendanceDeviceUserUploadContext";
import AttendanceDeviceProvider from "./attendanceDeviceContext";
import EmailGatewayProvider from "./emailGatewayContext";
import SMSGatewayProvider from "./smsGatewayContext";
import SMSSenderProvider from "./smsSenderContext";
import SMSTemplateProvider from "./smsTemplateContext";
import WhatsappGatewayProvider from "./whatsappGatewayContext";
import WhatsappTemplateProvider from "./whatsappTemplateContext";
import ZoomAccountProvider from "./zoomAccountContext";

const CommunicationMasterProvider = ({ children }) => {
  return (
    <ZoomAccountProvider>
      <AttendanceDeviceUserUploadProvider>
        <AttendanceDeviceConfigurationProvider>
          <AttendanceDeviceProvider>
            <WhatsappGatewayProvider>
              <WhatsappTemplateProvider>
                <EmailGatewayProvider>
                  <SMSGatewayProvider>
                    <SMSSenderProvider>
                      <SMSTemplateProvider>{children}</SMSTemplateProvider>
                    </SMSSenderProvider>
                  </SMSGatewayProvider>
                </EmailGatewayProvider>
              </WhatsappTemplateProvider>
            </WhatsappGatewayProvider>
          </AttendanceDeviceProvider>
        </AttendanceDeviceConfigurationProvider>
      </AttendanceDeviceUserUploadProvider>
    </ZoomAccountProvider>

  );
};

export default CommunicationMasterProvider;

CommunicationMasterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
