import AppSelectField from "@crema/components/AppFormComponents/AppSelectField";
import AppGridContainer from "@crema/components/AppGridContainer";
import WithGridItem from "@crema/components/with/WithGridItem";
import { Button } from "@mui/material";
import { Form } from "formik";
import getDetailsByMatchingKey from "helpers/GetDetailsByMatchedKey";
import { useEffect } from "react";
import { FaFilter } from "react-icons/fa";

const CommonFilterForm = ({
  academicYear,
  gridItemSize,
  values,
  updateDefaultFilters,
  selectSize = "small",
  handleCommonFilterClose,
  user,
  setFieldValue,
}) => {
  useEffect(() => {
    if (!values?.academic_year_id) return;
    const branch = getDetailsByMatchingKey(
      values?.branch_id,
      user?.branches,
      "branch_id",
    );
    if (values?.branch_type !== branch?.branch_type) {
      setFieldValue("branch_type", branch?.branch_type);
    }
    updateDefaultFilters({ ...values, ...branch });
  }, [values?.academic_year_id, values?.branch_id]);

  return (
    <Form>
      <AppGridContainer spacing={3}>
        <WithGridItem {...gridItemSize?.academicYear}>
          <AppSelectField
            size={selectSize}
            required={false}
            label="Academic Year"
            name="academic_year_id"
            options={academicYear?.data}
            selectionKey={"academic_year_name"}
            sx={smallInputStyles}
          />
        </WithGridItem>

        <WithGridItem {...gridItemSize?.branch}>
          <AppSelectField
            size={selectSize}
            required={false}
            label="Branch"
            name="branch_id"
            options={user?.branches}
            valueSelectionKey="branch_id"
            sx={smallInputStyles}
            getValue={(branchItem) =>
              `${branchItem?.branch_code} - ${branchItem?.branch_name}`
            }
          />
        </WithGridItem>
        {handleCommonFilterClose && (
          <WithGridItem xs={gridItemSize} md={gridItemSize} lg={gridItemSize}>
            <Button
              variant="contained"

              startIcon={<FaFilter />}
              sx={{
                float: "right",
              }}
              onClick={handleCommonFilterClose}
            >
              Filter
            </Button>
          </WithGridItem>
        )}
      </AppGridContainer>
    </Form>
  );
};

export default CommonFilterForm;

export const smallInputStyles = {
  width: "100%",
  fontSize: "12px",
  "& li": {
    fontSize: "12px !important",
  },
  "> div": {
    fontSize: "12px !important",
  },
  "> div > div": {
    padding: "6px 16px",
  },
  "& label": {
    fontSize: "12px !important",
    top: -2,
  },
};
