import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const MongoDBBackup = React.lazy(() =>
    import("modules/master/system/mongodbBackup")
);

const routes = {
    element: <MongoDBBackup />,
    title: "MongoDB Backup",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ["/mongodbbackups"],
};

export default routes;
