import React, { useEffect, useState } from "react";
import { Collapse, Icon, IconButton, ListItemText } from "@mui/material";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import VerticalItem from "../VerticalItem";
import Box from "@mui/material/Box";
import VerticalCollapseItem from "./VerticalCollapseItem";
import { useAppSettingContext } from "contexts/appSetting";

const needsToBeOpened = (pathname, item) => {
  return pathname && isUrlInChildren(item, pathname);
};

const isUrlInChildren = (parent, path) => {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], path)) {
        return true;
      }
    }

    if (
      parent.children[i].path === path ||
      path.includes(parent.children[i].path)
    ) {
      return true;
    }
  }

  return false;
};

const VerticalCollapse = ({ item, level }) => {
  const { appSetting } = useAppSettingContext();
  const { sidebarTextColor } = appSetting.sidebar;

  const { pathname } = useLocation();
  const [open, setOpen] = useState(() => needsToBeOpened(pathname, item));

  useEffect(() => {
    if (needsToBeOpened(pathname, item)) {
      setOpen(true);
    }
  }, [pathname, item]);

  const handleClick = () => {
    setOpen(!open);
  };

  if (!item?.hasPermission) {
    return null;
  }
  return (
    <>
      <VerticalCollapseItem
        level={level}
        sidebarTextColor={sidebarTextColor}
        button
        component="div"
        className={clsx("menu-vertical-collapse", open && "open")}
        onClick={handleClick}
      >
        {item.icon && (
          <Box component="span">
            <Icon
              sx={{ mr: 4 }}
              color="action"
              className={clsx("nav-item-icon")}
            >
              {item.icon}
            </Icon>
          </Box>
        )}
        <ListItemText
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: 14,
            mt: -0.2,
          }}
          className="nav-item-content"
          classes={{ primary: clsx("nav-item-text") }}
          primary={item.title}
        />
        <IconButton
          className="nav-item-icon-arrow-btn"
          sx={{ p: 0, mr: 0.75 }}
          disableRipple
          size="large"
        >
          <Icon className="nav-item-icon-arrow" color="inherit">
            {/* {open
              ? 'expand_more'
              : theme.direction === 'ltr'
              ? 'chevron_right'
              : 'chevron_left'} */}
          </Icon>
        </IconButton>
      </VerticalCollapseItem>

      {item.children && (
        <Collapse in={open} className="collapse-children">
          {item.children.map((item, index) => (
            <React.Fragment key={index}>
              {item.type === "collapse" && (
                <VerticalCollapse item={item} level={level + 1} />
              )}

              {item.type === "item" && (
                <VerticalItem item={item} level={level + 1} />
              )}
            </React.Fragment>
          ))}
        </Collapse>
      )}
    </>
  );
};

export default React.memo(VerticalCollapse);
