import { USER_ROLE } from "constants/AppConstant";
import { MdAccountBalanceWallet } from "react-icons/md";
import accountSummaryRoutes from "./accountSummaryRoutes";
import feesConcessionRoutes from "./feesConcesssionRoutes";
import paymentTransactionDateRoutes from "./paymentTransactionDateRoutes";
import paymentTransactionRoutes from "./paymentTransactionRoutes";
import paymentTransactionSummaryByDivisionRoutes from "./paymentTransactionSummaryByDivisionRoutes";
import paymentTransactionSummaryRoutes from "./paymentTransactionSummaryRoutes";
import studentFeesStructureInstallmentByDueDate from "./studentFeesStructureInstallmentByDueDate";




const menu = {
    id: "Account",
    title: "Account",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    type: "collapse",
    icon: <MdAccountBalanceWallet />,
    path: ["/accountMenu"],
    children: [studentFeesStructureInstallmentByDueDate, paymentTransactionDateRoutes, paymentTransactionSummaryRoutes, paymentTransactionRoutes, paymentTransactionSummaryByDivisionRoutes,
        feesConcessionRoutes, accountSummaryRoutes,
    ],
};

export default menu;
