import AppAutocompleteField from "@crema/components/AppFormComponents/AppAutocompleteField"
import { useAuthUser } from "@crema/hooks/AuthHooks"
import { Box, TextField } from "@mui/material"
import { Form, Formik } from "formik"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { smallInputStyles } from "./CommonFilterForm"
const PageSearchBar = forwardRef(({ dynamicRoutes }, ref) => {

    const { user } = useAuthUser();

    const [isFocused, setIsFocused] = useState(false);

    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
        setIsFocused: setIsFocused,
        input: inputRef.current,
    }), [inputRef.current])

    const finalRoutes = useMemo(() => {
        const routesSet = {};
        dynamicRoutes.forEach((route) => {
            if (route.title && user?.role_name === route.permittedRole) {
                routesSet[route.title] = route.path;
            }
        });
        return Object.entries(routesSet).map((route) => ({ _id: route[1], title: route[0] }));
    }, [dynamicRoutes, user?.role_name])


    const navigator = useNavigate();


    return (
        <Box sx={{
            width: 200
        }}>
            <Formik>
                <Form>
                    <AppAutocompleteField
                        focused={isFocused}
                        componentsProps={{
                            input: {
                                ref: inputRef,
                            },
                        }}
                        openOnFocus={true}
                        options={finalRoutes}
                        onChange={(event, newValue) => {
                            if (newValue && newValue?._id) {
                                navigator(newValue?._id, {});
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                }}
                                label="Search"
                            />
                        )}
                        sx={{
                            ...smallInputStyles, "& .MuiInputBase-root": {
                                padding: "0 !important",
                            }
                        }}
                        freeSolo
                        size="small"
                        autoHighlight
                        getOptionLabel={(option) => {
                            return `${option?.title}`;
                        }}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <li key={key} {...optionProps}>
                                    {option?.title}&nbsp;
                                </li>
                            );
                        }}
                    />
                </Form>
            </Formik>
        </Box>
    )
});

export default PageSearchBar