import PropTypes from "prop-types";
import LiveClassScheduleProvider from "./liveClassScheduleContext";


const LiveClassProvider = ({ children }) => {
    return (

        <LiveClassScheduleProvider>
            {children}
        </LiveClassScheduleProvider>

    );
};

export default LiveClassProvider;

LiveClassProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
