import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const AdmissionStudentInventory = React.lazy(() =>
  import("modules/student/admission/admissionStudentInventory")
);
const routes = {
  path: ["/student/inventories"],
  element: <AdmissionStudentInventory />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
};

export default routes;
