import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const StudentRegistrations = React.lazy(() => import("modules/student/studentRegistrations"));

const routes = {
    title: "Quick View",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ["/studentregistrations"],
    element: <StudentRegistrations />,
    type: "item",
};

export default routes;
