import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { USER_ROLE } from "constants/AppConstant";
import demographicSetupRoutes from "./demographicSetupRoutes";
import holidaySetupRoutes from "./holidaySetupRoutes";
import locationSetupRoutes from "./locationSetupRoutes";
import lockerSetupRoutes from "./lockerSetupRoutes";
import mongodbBackupRoutes from "./mongodbBackupRoutes";
import permissionSetupRoutes from "./permissionSetupRoutes";

export default {
  id: "System",
  title: "System",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: "collapse",
  icon: <SettingsSuggestIcon />,
  path: ["/systemMenu"],

  children: [
    permissionSetupRoutes,
    locationSetupRoutes,
    demographicSetupRoutes,
    lockerSetupRoutes,
    holidaySetupRoutes,
    mongodbBackupRoutes,
  ],
};
