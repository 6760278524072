import React from "react";
import { USER_ROLE } from "constants/AppConstant";

const AdmissionSetup = React.lazy(() =>
  import("modules/master/academic/admissionSetup")
);

const routes = {
  element: <AdmissionSetup />,
  title: "Admission Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    "/admission/academicachievements",
    "/admission/admissionsources",
    "/admission/learningmodes",
  ],
};

export default routes;
