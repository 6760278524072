import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const IdentityCardSetup = React.lazy(() =>
    import("modules/master/academic/identityCard")
);

const routes = {
    element: <IdentityCardSetup />,
    title: "ID Card Layout",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ["/identitycards"],
};

export default routes;
