import { USER_ROLE } from "constants/AppConstant";
import React from "react";
const StudentFees = React.lazy(() =>
  import("modules/student/studentFeesReport")
);

const routes = {
  title: "Fees Report",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/student/feesreports"],
  element: <StudentFees />,
  type: "item",
};

export default routes;
