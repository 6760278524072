import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const LocationSetup = React.lazy(() =>
  import("modules/master/system/locationSetup")
);

const routes = {
  element: <LocationSetup />,
  title: "Location Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/location/states", "/location/districts"],
};

export default routes;
