import React from 'react';
import PropsTypes from 'prop-types';
import { alpha, Box } from '@mui/material';
import { useAppSettingContext } from 'contexts/appSetting';

const HeaderNavWrapper = ({ children }) => {
  const { appSetting } = useAppSettingContext();
  const {
    sidebarBgColor,
    sidebarTextColor,
    sidebarMenuSelectedBgColor,
    sidebarMenuSelectedTextColor,
  } = appSetting.sidebar;

  return (
    <Box
      sx={{
        backgroundColor: sidebarBgColor,
        color: sidebarTextColor,
        span: {
          fontSize: 16,
        },
        py: 2.5,
        '& .navbarNav': {
          display: 'flex',
          padding: 0,
        },
        '& .navItem': {
          width: 'auto',
          cursor: 'pointer',
          py: 1,
          px: { xs: 4, lg: 5 },
          borderRadius: 1,
          '&.active': {
            color: sidebarMenuSelectedTextColor,
            backgroundColor: alpha(sidebarMenuSelectedBgColor, 0.8),
            '& .navLinkIcon': {
              color: 'inherit',
            },
          },
        },
        '& .navLinkIcon': {
          mr: 2.5,
          color: (theme) => theme.palette.primary.main,
          fontSize: 24,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default HeaderNavWrapper;

HeaderNavWrapper.propTypes = {
  children: PropsTypes.node,
};
