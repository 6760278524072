import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const TimeTableSetup = React.lazy(() =>
  import("modules/master/academic/timeTableSetup")
);

const routes = {
  element: <TimeTableSetup />,
  title: "Time Table Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    "/timetables",
    "/timetables/timeslots",
    "/timetables/teachingloads",
    "/timetables/view",
  ],
};

export default routes;
