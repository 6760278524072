import React from "react";
import { USER_ROLE } from "constants/AppConstant";

const EntranceProceedToLogin = React.lazy(() =>
  import("modules/student/entrance/entranceStudentProceedToLogin")
);

const routes = {
  permittedRole: [USER_ROLE.PUBLIC],
  path: ["entrance/proceedToLogin"],
  element: <EntranceProceedToLogin />,
};

export default routes;
