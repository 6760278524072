import {
  Grow,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import clsx from "clsx";
import { useAppSettingContext } from "contexts/appSetting";
import React, { useState } from "react";
import * as ReactDOM from "react-dom";
import { Manager, Popper, Reference } from "react-popper";
import { useLocation } from "react-router-dom";
import HorizontalGroup from "./HorizontalGroup";
import HorizontalItem from "./HorizontalItem";

import { CSS_CONSTANTS } from "constants/AppConstant";

function HorizontalCollapse(props) {
  const [opened, setOpened] = useState(false);
  const location = useLocation();
  const { item, nestedLevel, dense } = props;
  const active = isUrlInChildren(item, location.pathname);

  const { appSetting } = useAppSettingContext();
  const { sidebarMenuSelectedBgColor, sidebarMenuSelectedTextColor } =
    appSetting.sidebar;

  const handleToggle = (open) => {
    setOpened(open);
  };

  function isUrlInChildren(parent, path) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (isUrlInChildren(parent.children[i], path)) {
          return true;
        }
      }

      if (
        parent.children[i].path === path ||
        path.includes(parent.children[i].path)
      ) {
        return true;
      }
    }

    return false;
  }

  if (!item?.hasPermission) {
    return null;
  }

  return (
    <List
      sx={{
        py: 0,
        "& .list-item-text": {
          padding: "0 0 0 16px",
        },
      }}
      className="navbarNavSubmenu"
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <ListItem
              ref={ref}
              button
              sx={{
                textTransform: "uppercase",
                padding: "0px 12px",
                "&.active, &.active:hover, &.active:focus": {
                  backgroundColor: sidebarMenuSelectedBgColor + "!important",
                  color: sidebarMenuSelectedTextColor + "!important",
                },
                ".svgIcon": {
                  color: (theme) => theme.palette.primary.main,
                },
                "&.open": {
                  backgroundColor: "rgba(0,0,0,.08)",
                },
                "&.dense": {
                  padding: "0px 12px",
                  "& .list-item-text": {
                    padding: "0 0 0 8px",
                  },
                },
              }}
              className={clsx(
                "navItemSubmenu",
                opened && "open",
                dense && "dense",
                active && "active"
              )}
              onMouseEnter={() => handleToggle(true)}
              onMouseLeave={() => handleToggle(false)}
              aria-owns={opened ? "menu-list-grow" : null}
              aria-haspopup={true}
            >
              {item.icon && (
                <Icon
                  sx={{
                    color: active ? sidebarMenuSelectedTextColor : "action",
                    mr: 3.5,
                    fontSize: { xs: 16, xl: 18 },
                  }}
                  className="svgIcon"
                >
                  {item.icon}
                </Icon>
              )}
              <ListItemText
                className="navLinkTextSubmenu"
                primary={item.title}
              />
              <Box p={0}>
                <IconButton disableRipple>
                  <Icon
                    sx={{
                      color: active ? sidebarMenuSelectedTextColor : "action",
                    }}
                  >
                    chevron_right
                  </Icon>
                </IconButton>
              </Box>
            </ListItem>
          )}
        </Reference>
        {ReactDOM.createPortal(
          <Popper
            placement="right"
            eventsEnabled={opened}
            positionFixed
          >
            {({ ref, style, placement }) =>
              opened && (
                <Box
                  ref={ref}
                  sx={{
                    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.2)",
                    // zIndex: 1110 + nestedLevel + 1,
                    zIndex: CSS_CONSTANTS.Z_INDEX.HORIZONTAL_COLLAPSE,
                    ...style,
                    "& .popperClose": {
                      pointerEvents: "none",
                    },
                  }}
                  data-placement={placement}
                  className={clsx({
                    popperClose: !opened,
                  })}
                >
                  <Grow
                    in={opened}
                    id="menu-list-grow"
                    sx={{ transformOrigin: "0 0 0" }}
                  >
                    <Paper
                      onMouseEnter={() => handleToggle(true)}
                      onMouseLeave={() => handleToggle(false)}
                    >
                      {item.children && (
                        <List
                          sx={{
                            px: 0,
                          }}
                        >
                          {item.children.map((item) => (
                            <React.Fragment key={item?.title}>
                              {item.type === "group" && (
                                <HorizontalGroup
                                  item={item}
                                  nestedLevel={nestedLevel + 1}
                                />
                              )}

                              {item.type === "collapse" && (
                                <HorizontalCollapse
                                  item={item}
                                  nestedLevel={nestedLevel + 1}
                                  location={location}
                                />
                              )}

                              {item.type === "item" && (
                                <HorizontalItem
                                  item={item}
                                  nestedLevel={nestedLevel + 1}
                                />
                              )}
                            </React.Fragment>
                          ))}
                        </List>
                      )}
                    </Paper>
                  </Grow>
                </Box>
              )
            }
          </Popper>,
          document.querySelector("#root")
        )}
      </Manager>
    </List>
  );
}

export default React.memo(HorizontalCollapse);
