import PropTypes from "prop-types";
import AdmissionSummaryProvider from "./admissionSummaryContext";
import CompetitiveExamStatisticsProvider from "./competitiveExamStatisticsContext";
import EntranceStatisticsProvider from "./entranceStatisticsContext";
import EntranceSummaryProvider from "./entranceSummaryContext";
import PaymentTransactionSummaryByDivisionProvider from "./paymentTransactionSummaryByDivisionContext";
import PaymentTransactionSummaryByMonthProvider from "./paymentTransactionSummaryByMonthContext";
import PaymentTransactionSummaryProvider from "./paymentTransactionSummaryContext";
import StudentAttendanceSummaryProvider from "./studentAttendanceSummaryContext";
import StudentFeesConcessionByDivisionProvider from "./studentFeesConcessionByDivisionContext";
import StudentFeesConcessionProvider from "./studentFeesConcessionContext";
import UserAttendanceSummaryProvider from "./userAttendanceSummaryContext";

const DashboardContextProvider = ({ children }) => {
  return (
    <UserAttendanceSummaryProvider>
      <CompetitiveExamStatisticsProvider>
        <PaymentTransactionSummaryByMonthProvider>
          <StudentAttendanceSummaryProvider>
            <StudentFeesConcessionByDivisionProvider>
              <StudentFeesConcessionProvider>
                <PaymentTransactionSummaryByDivisionProvider>
                  <PaymentTransactionSummaryProvider>
                    <AdmissionSummaryProvider>
                      <EntranceSummaryProvider>
                        <EntranceStatisticsProvider>
                          {children}
                        </EntranceStatisticsProvider>
                      </EntranceSummaryProvider>
                    </AdmissionSummaryProvider>
                  </PaymentTransactionSummaryProvider>
                </PaymentTransactionSummaryByDivisionProvider>
              </StudentFeesConcessionProvider>
            </StudentFeesConcessionByDivisionProvider>
          </StudentAttendanceSummaryProvider>
        </PaymentTransactionSummaryByMonthProvider>
      </CompetitiveExamStatisticsProvider>
    </UserAttendanceSummaryProvider>
  );
};

export default DashboardContextProvider;

DashboardContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
