// React imports
import { createContext, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state
import { DISPATCH_TYPES } from "constants/ActionTypes";
import reducer from "reducers/student/studentFeesStructureInstallmentByDueDateReducer";

// context to be used in the app
const StudentFeesStructureInstallmentByDueDateContext = createContext();

// hook to use the context states in the ui
const useStudentFeesStructureInstallmentByDueDateContext = () => {
  return useContext(StudentFeesStructureInstallmentByDueDateContext);
};
export { useStudentFeesStructureInstallmentByDueDateContext };

// Initial StudentFeesStructureInstallmentByDueDate
const initialState = {
  data: [],
  count: 0,
  exportList: [],
};

// provider of the context
const StudentFeesStructureInstallmentByDueDateProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName =
    API_NAMES.STUDENT.STUDENT_FEES_STRUCTURE_INSTALLMENT_BY_DUE_DATE;

  // Fetches whole records from DB
  const getList = (params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  };

  // Fetches whole records from DB
  const sendSMS = (params, callbackFunction) => {
    return GetListAPI(
      apiName + "sendsms",
      () => {},
      params,
      callbackFunction,
      null,
      "DFJKD"
    );
  };

  // Fetches whole records from DB
  const getExportList = (params, callbackFunction, onError) => {
    return GetListAPI(
      apiName,
      dispatch,
      params,
      callbackFunction,
      onError,
      DISPATCH_TYPES.EXPORT
    );
  };

  return (
    <StudentFeesStructureInstallmentByDueDateContext.Provider
      value={{
        data: state.data,
        count: state.count,
        exportList: state.exportList,
        sendSMS,
        getList,
        getExportList,
      }}
    >
      {children}
    </StudentFeesStructureInstallmentByDueDateContext.Provider>
  );
};

export default StudentFeesStructureInstallmentByDueDateProvider;
