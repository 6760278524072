import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const StudentManualAttendance = React.lazy(() =>
    import("modules/attendance/studentManualAttendance")
);

const routes = {
    element: <StudentManualAttendance />,
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ["/attendance/studentmanualattendance"],
    title: "Student Manual ATD",
    type: "item",
};

export default routes;
