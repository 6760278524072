// React imports
import { createContext, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state
import { getFormattedDateTime } from "@crema/helpers/DateHelper";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { useNotificationActionsContext } from "contexts/common/notificationContext";
import reducer from "reducers/master/system/mongodbBackupReducer";

// context to be used in the app
const MongodbBackupContext = createContext();

// hook to use the context states in the ui
const useMongodbBackupContext = () => {
    return useContext(MongodbBackupContext);
};
export { useMongodbBackupContext };

// Initial MongodbBackup
const initialMongodbBackup = {
    data: [],
    count: 0,
};

// provider of the context
const MongodbBackupProvider = ({ children }) => {
    // API helper functions to hit APi's
    const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();

    const { fetchError, fetchStart, fetchSuccess, showMessage } = useNotificationActionsContext();
    // Reducer to set state
    const [state, dispatch] = useReducer(reducer, initialMongodbBackup);

    // Name of the api to hit
    const apiName = API_NAMES.MASTER.SYSTEM.MONGODB_BACKUP;

    // Fetches whole records from DB
    const getList = (params, callbackFunction) => {
        fetchStart();
        jwtAxios.get(apiName, { responseType: "blob" }).then((data) => {
            fetchSuccess()
            showMessage("Downloading Backup...");
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // Assuming the backend sends a file with a known name, you can set it here
            a.download = `mongodb-backup-${getFormattedDateTime(new Date())}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch((error) => {
            console.log(error);
            fetchError(error?.response?.data?.message || "Something went wrong...")
        }).finally(() => {

        })
    };

    // Fetches single record in records of DB
    const getItem = (_id) => {
        GetAPI(apiName, dispatch, _id);
    };

    // Update single record in records of DB
    const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
        UpdateAPI(
            apiName,
            dispatch,
            formData,
            setSubmitting,
            resetForm,
            handleAddClose
        );
    };

    // Add new record in records of DB
    const createItem = (formData, setSubmitting, resetForm, handleAddClose) => {
        CreateAPI(
            apiName,
            dispatch,
            formData,
            setSubmitting,
            resetForm,
            handleAddClose
        );
    };

    // Delete on or may records from records in DB
    const deleteItems = (params, _ids) => {
        DeleteAPI(apiName, dispatch, params, _ids);
    };

    return (
        <MongodbBackupContext.Provider
            value={{
                data: state.data,
                count: state.count,
                getList,
                getItem,
                updateItem,
                createItem,
                deleteItems,
            }}
        >
            {children}
        </MongodbBackupContext.Provider>
    );
};

export default MongodbBackupProvider;
