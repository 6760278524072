import { Box } from "@mui/material";
import { CSS_CONSTANTS } from "constants/AppConstant";
import { FONTS } from "constants/AppEnum";
import { useAppSettingContext } from "contexts/appSetting";
import { Link } from "react-router-dom";

const AppLogo = ({ sx }) => {
  const { appSetting } = useAppSettingContext();

  const styles = {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    fontSize: CSS_CONSTANTS.FONT_SIZE.HEADING_3,
    fontWeight: FONTS.BOLD,
    color: "primary.main",
    ml: 3,
    "& img": {
      height: "100%",
    },
  };

  return (
    <Link to={appSetting.initialURL}>
      <Box
        sx={sx ? { ...styles, ...sx } : styles}
        className="app-logo"
      >
        {/* <img
        srcSet='https://play-lh.googleusercontent.com/7uCJO4cIeS9oLWFxdBLkUBQHP-iH30OtUEmYM6EdH-DvHVv8Xc6hIV8aVwl7zvlSRw=w240-h480-rw'
        src='https://play-lh.googleusercontent.com/7uCJO4cIeS9oLWFxdBLkUBQHP-iH30OtUEmYM6EdH-DvHVv8Xc6hIV8aVwl7zvlSRw=w240-h480-rw'
        alt='logo'
        loading='lazy'
        /> */}
        Netizens
      </Box>
    </Link>
  );
};

export default AppLogo;
