import React from "react";
import { USER_ROLE } from "constants/AppConstant";
import { MdAssignment } from "react-icons/md";

const AdmissionStudentAssignment = React.lazy(() =>
  import("modules/student/admission/admissionStudentAssignment")
);

const routes = {
  permittedRole: [USER_ROLE.STUDENT],
  title: "Assignments",
  path: ["/student/assignments"],
  element: <AdmissionStudentAssignment />,
  type: "item",
  icon: <MdAssignment />,
};

export default routes;


