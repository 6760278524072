import { Autocomplete } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";
// import AppAutoComplete from "./AppAutoComplete";

const AppAutocompleteField = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <Autocomplete
      {...field}
      {...props}
      helperText={!props.disabled ? errorText : ""}
      error={!!errorText}
    />
  );
};

export default AppAutocompleteField;

AppAutocompleteField.propTypes = {
  disabled: PropTypes.bool,
};
