import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const LiveClassSchedule = React.lazy(() =>
    import("modules/liveClass/liveClassSchedule")
);

const routes = {
    element: <LiveClassSchedule />,
    title: "Class Schedule",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: [
        "/liveclassschedules"
    ],
};

export default routes;
