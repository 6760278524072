import axios from "axios";

const jwtAxios = axios?.create({
  // baseURL: "http://localhost:4000/api/",
  //
  // baseURL: "http://10.1.10.83:4000/api/",

  // baseURL: "https://api.netizens.live/api/",
  // baseURL: "",
  // baseURL: "http://10.1.10.48:4000/api/",
  baseURL: "https://api.netizens.edu.in/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAuthToken = (authToken, refreshToken) => {
  if (authToken) {
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
    localStorage.setItem("authToken", authToken);
  } else {
    delete jwtAxios.defaults.headers.common["Authorization"];
    localStorage.removeItem("authToken");
  }
  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
};

export const setPermissions = (permissions) => {
  localStorage.setItem("permissions", permissions);
};

export default jwtAxios;
