import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const EntranceStatistics = React.lazy(() =>
    import("modules/student/entrance/entranceStatistics")
);

const routes = {
    title: "Statistics",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ["/entrance/statistics"],
    element: <EntranceStatistics />,
    type: "item",
};

export default routes;
