import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import BrochureRoutes from "./BrochureRoutes.js";
import { USER_ROLE } from "constants/AppConstant.js";

const routes = {
  title: "Admission ",
  type: "group",
  icon: <FaCheckCircle />,
  children: [BrochureRoutes],
  permittedRole: [USER_ROLE.PUBLIC],
};

export default routes;
