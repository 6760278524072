import React from "react";
import { USER_ROLE } from "constants/AppConstant";

const EntranceTestSchedule = React.lazy(
  () => import("modules/master/academic/entranceTestSchedule")
);

const routes = {
  title: "Test Schedule",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/entrance/entrancetestschedules"],
  element: <EntranceTestSchedule />,
  type: "item",
};

export default routes;
