import React from "react";
import AppErrorBoundary from "../AppErrorBoundary";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import AppContentViewWrapper from "./AppContentViewWrapper";
import AppSuspense from "../AppSuspense";

const AppContentView = ({ sxStyle, routes }) => {
  return (
    <AppContentViewWrapper>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          ...sxStyle,
        }}
        className="app-content"
      >
        <AppSuspense>
          <AppErrorBoundary>{routes}</AppErrorBoundary>
        </AppSuspense>
      </Box>
    </AppContentViewWrapper>
  );
};

export default AppContentView;
