import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";
import ErrorIcon from "./ErrorIcon";

const AppErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary
      fallbackRender={Fallback}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

const Fallback = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
        textAlign: "center",
        "& svg": {
          width: "100%",
          maxWidth: 400,
          color: (theme) => theme.palette.primary.main,
        },
      }}
    >
      <ErrorIcon />
      <Typography
        variant="h2"
        component="h2"
        style={{ fontSize: 30, marginTop: 16 }}
      >
        Ah! something went wrong!
      </Typography>
      <Typography style={{ fontSize: 18, marginTop: 12 }}>
        Brace yourself till we get the error fixed.
      </Typography>
      <Typography style={{ fontSize: 18 }}>
        You may also refresh the page or try again latter
      </Typography>
      <Button
        variant="contained"

        color="primary"

        sx={{
          marginTop: 16,
        }}
        onClick={resetErrorBoundary}
      >
        Try again
      </Button>
    </Box>
  );
};

export default AppErrorBoundary;

AppErrorBoundary.propTypes = {
  children: PropTypes.node,
};

// class AppErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//     this.storeError = (error, errorInfo) => {
//       jwtAxios
//         .post('errors', {
//           componentStack: errorInfo,
//           message: error.message,
//         })
//         .then((data) => {
//         })
//         .catch((error) => {
//           // fetchError(error.message);
//         });
//     };
//   }

//   static getDerivedStateFromError(error) {
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     // this.storeError(error, errorInfo);
//   }

//   render() {
//     if (this.state.hasError) {
//       return (
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             flex: 1,
//             textAlign: 'center',
//             '& svg': {
//               width: '100%',
//               maxWidth: 400,
//               color: (theme) => theme.palette.primary.main,
//             },
//           }}
//         >
//           <ErrorIcon />
//           <Typography
//             variant='h2'
//             component='h2'
//             style={{ fontSize: 30, marginTop: 16 }}
//           >
//             Ah! something went wrong!
//           </Typography>
//           <Typography style={{ fontSize: 18, marginTop: 12 }}>
//             Brace yourself till we get the error fixed.
//           </Typography>
//           <Typography style={{ fontSize: 18 }}>
//             You may also refresh the page or try again latter
//           </Typography>
//           <Button
//             color='primary'
//             variant='contained'
//             sx={{
//               marginTop: 16,
//             }}
//             onClick={() =>
//               this.setState({
//                 hasError: false,
//               })
//             }
//           >
//             Try again
//           </Button>
//         </Box>
//       );
//     } else {
//       return this.props.children;
//     }
//   }
// }
