import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const IdCard = React.lazy(() =>
    import("modules/master/academic/generateIdCard")
);

const routes = {
    element: <IdCard />,
    title: "Generate ID Card",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ["/idcards"],
};

export default routes;
