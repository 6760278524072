import { API_NAMES } from "constants/ApiConstants";

const { USER_ROLE, STUDENT_CONSTANT } = require("constants/AppConstant");

export const getRoleFromLocalStorage = () =>
  localStorage.getItem("userRole") || USER_ROLE.PUBLIC;


export const getLoginRouteFromRoleName = (roleName) => {
  switch (roleName) {
    case USER_ROLE?.STUDENT:
      return API_NAMES.STUDENT.STUDENT_LOGIN;

    case USER_ROLE?.ENTRANCE_STUDENT:
      return API_NAMES.STUDENT.STUDENT_LOGIN;

    case USER_ROLE?.SUPER_ADMIN:
      return API_NAMES.USER.LOGIN;

    case USER_ROLE?.ADMIN:
      return API_NAMES.USER.LOGIN;

    case USER_ROLE?.DESK_USER:
      return API_NAMES.USER.LOGIN;

    default:
      return API_NAMES.STUDENT.STUDENT_LOGIN;
  }
};

export const getStudentTypeOnAdmissionMode = (admissionMode) => {
  switch (admissionMode) {
    case STUDENT_CONSTANT.ADMISSION_MODE.ENTRANCE:
      return USER_ROLE.ENTRANCE_STUDENT;
    case STUDENT_CONSTANT.ADMISSION_MODE.RENEWAL:
      return USER_ROLE.STUDENT;
    default:
      return USER_ROLE.ENTRANCE_STUDENT;
  }
};

export const isStudent = (role_name) =>
  role_name === USER_ROLE.STUDENT || role_name === USER_ROLE.ENTRANCE_STUDENT;
