import React from "react";
import PropTypes from "prop-types";
import AppLoader from "../AppLoader";
import LoadingBar from "react-top-loading-bar";
import { useAppSettingContext } from "contexts/appSetting";

const AppSuspense = (props) => {
  const { appSetting } = useAppSettingContext();

  return (
    <React.Suspense
      fallback={
        <>
          <LoadingBar
            color={appSetting?.theme?.palette?.secondary?.main}
            progress={90}
            height={4}
            shadow
          />
          <AppLoader />
        </>
      }
    >
      {props.children}
    </React.Suspense>
  );
};

AppSuspense.propTypes = {
  loadingProps: PropTypes.object,
};

AppSuspense.defaultProps = {
  loadingProps: {
    delay: 300,
  },
};

export default AppSuspense;

AppSuspense.propTypes = {
  children: PropTypes.node.isRequired,
};
