import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const ClassSetup = React.lazy(() =>
  import("modules/master/academic/classSetup")
);

const routes = {
  element: <ClassSetup />,
  title: "Class Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    "/classes/buildings",
    "/classes/classrooms",
    "/classes/standards",
    "/classes/divisions",
    "/classes/divisiongroups",
    "/classes/virtualdivisions",
    "/classes/standardbranchacademicyearmappings",
    "/classes/divisionstandardbranchacademicyearmappings",
    "/classes/divisionsubjectmappings",
  ],
};

export default routes;
