// React imports
import { createContext, useCallback, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state
import reducer from "reducers/admin/dashboard/competitiveExamStatisticsReducer";

// context to be used in the app
const CompetitiveExamStatisticsContext = createContext();

// hook to use the context states in the ui
const useCompetitiveExamStatisticsContext = () => {
    return useContext(CompetitiveExamStatisticsContext);
};
export { useCompetitiveExamStatisticsContext };

// Initial State
const initialState = {
    data: [],
    count: 0,
};

// provider of the context
const CompetitiveExamStatisticsProvider = ({ children }) => {
    // API helper functions to hit APi's
    const { GetListAPI } = ApiHelper();

    // Reducer to set state
    const [state, dispatch] = useReducer(reducer, initialState);

    // Name of the api to hit
    const apiName = API_NAMES.ADMIN.DASHBOARD.COMPETITIVE_EXAM_STATISTICS;

    // Fetches whole records from DB
    const getList = useCallback((params, callbackFunction) => {
        return GetListAPI(apiName, dispatch, params, callbackFunction);
    }, []);

    return (
        <CompetitiveExamStatisticsContext.Provider
            value={{
                data: state.data,
                count: state.count,
                getList,
            }}
        >
            {children}
        </CompetitiveExamStatisticsContext.Provider>
    );
};

export default CompetitiveExamStatisticsProvider;
