// React Utilities
import React, { useCallback } from "react";
import PropTypes from "prop-types";

// Crema Componenets
import AppDialog from "@crema/components/AppDialog";

// MUI icons
import SecurityIcon from "@mui/icons-material/Security";
import { CSS_CONSTANTS } from "constants/AppConstant";
import Button from "@mui/material/Button";
// Custom Component
import {
  renderParagraphs,
  renderHeading,
  renderList,
} from "helpers/ComponentHelper";

const PrivacyPolicy = ({ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen }) => {
  // It will handle the click on the close button of dialog box
  const handleClose = useCallback(
    () => setIsPrivacyPolicyOpen(false),
    [isPrivacyPolicyOpen]
  );
  return (
    <AppDialog
      dividers
      dialogIcon={<SecurityIcon />}
      title="Privacy Policy"
      onClose={handleClose} // It will close the dialog box
      open={isPrivacyPolicyOpen}
      sxStyle={{
        "& .MuiDialog-paper": { maxWidth: CSS_CONSTANTS.FORM_SIZE.BIG },
      }}
    >
      {renderParagraphs([
        <>
          Thanks for visiting &nbsp;
          <a href="http://www.netizens.live">www.netizens.live</a>
        </>,
      ])}

      {renderParagraphs([
        <>
          Netizens Foundation School (<strong>NFS</strong>) (NETIZENS or
          &lsquo;we&apos; or &lsquo;us&apos;) recognizes the importance of
          maintaining your privacy. This privacy policy is a legally binding
          document between you and NFS.
        </>,
        <>
          This privacy policy applies to the collection, use, storage,
          disclosure, transfer or processing of personal information or
          sensitive personal information by us through your use of the Website
          and related application, products and services.
        </>,
        <>
          This privacy policy is subject to the &nbsp;
          <a href="http://www.netizens.edu.in/terms-of-use.asp">
            Terms &amp; Conditions
          </a>
          &nbsp; of Use of NFS. This policy is effective from the date and time
          a user registers with or visits the Website and/or by filling up the
          Registration form.
        </>,
        <>
          By accessing or using the Website or any services, you consent to the
          information collection, disclosure and use practices described in this
          Privacy Policy. Please also review our Terms &amp; Conditions of Use,
          which governs your use of the service. Terms capitalized but not
          defined herein shall have the meaning ascribed to them in the Terms
          &amp; Conditions of Use.
        </>,
      ])}
      {renderParagraphs([
        <>
          Netizens Foundation School (<strong>NFS</strong>) (NETIZENS or
          &lsquo;we&apos; or &lsquo;us&apos;) recognizes the importance of
          maintaining your privacy. This privacy policy is a legally binding
          document between you and NFS.
        </>,
        <>
          This privacy policy applies to the collection, use, storage,
          disclosure, transfer or processing of personal information or
          sensitive personal information by us through your use of the Website
          and related application, products and services.
        </>,
        <>
          This privacy policy is subject to the &nbsp;
          <a href="http://www.netizens.edu.in/terms-of-use.asp">
            Terms &amp; Conditions
          </a>
          &nbsp; of Use of NFS. This policy is effective from the date and time
          a user registers with or visits the Website and/or by filling up the
          Registration form.
        </>,
        <>
          By accessing or using the Website or any services, you consent to the
          information collection, disclosure and use practices described in this
          Privacy Policy. Please also review our Terms &amp; Conditions of Use,
          which governs your use of the service. Terms capitalized but not
          defined herein shall have the meaning ascribed to them in the Terms
          &amp; Conditions of Use.
        </>,
      ])}

      {renderHeading("1. INFORMATION THAT WE COLLECT")}

      {renderParagraphs([
        <>
          NFS collects data to operate effectively and provide you the best
          experience with our services. You will provide some of this data
          directly, such as when you sign up for our email communication or
          register for our program through our Website or Landing pages. We also
          collect the information in other various ways such as on social media
          channels, in-person communication, or during the pre-counselling
          process when we may contact you for additional clarification regarding
          your profile or to organize one-to-one meetings for you.
        </>,
        <>
          We also obtain data from third parties. We protect data obtained from
          third parties according to the practices described in this policy,
          plus any additional restrictions imposed by the source of the data.
          These third-party sources vary over time, but have included:
        </>,
      ])}
      {renderList([
        <>Database providers from trusted sources</>,
        <>Social networks when you grant permission to access your data</>,
        <>
          Partners with which we offer co-branded services or engage in joint
          marketing activities
        </>,
        <>
          Publicly-available sources such as open government databases or other
          data in the public domain
        </>,
      ])}

      {renderParagraphs([
        <>
          Please note that in relation to any data provided by third-party
          database provider it is their responsibility to ensure that they have
          obtained the necessary consent from you to share your personal
          information.
        </>,
        <>
          You have choices about the data we collect. When you are asked to
          provide personal data, you may decline. But if you choose not to
          provide data that is necessary to provide a service, you may not be
          able to use that service.
        </>,
        <>
          The data we collect depends on the context of your interactions with
          NFS. The data we collect can include the following:
        </>,
      ])}

      {renderList([
        <>
          if you have consented to the collection and processing of your
          personal information;
        </>,
        <>
          if personal information is required to perform a contract with you;
        </>,
        <>
          if processing of your personal information is necessary for us to
          comply with a legal obligation;
        </>,
        <>
          if processing of your personal information is necessary to us to
          protect your vital interests or the vital interests of another natural
          person;
        </>,
        <>
          if processing of your personal information is necessary for us to
          perform a task in the public interest; or
        </>,
        <>
          if the processing of personal information is in our legitimate
          interests (such as processing for network and information security,
          direct marketing, preventing fraud or criminal acts) and not
          overridden by your data protection interests or fundamental rights and
          freedoms.
        </>,
      ])}

      {renderParagraphs([
        <>
          <strong>Information Collected by Automated Means</strong>
          When you visit our websites, we may collect certain information by
          automated means, such as cookies, web beacons and web server logs. The
          information we may collect in this manner includes:
        </>,
      ])}

      {renderList([
        <>IP address</>,
        <>Unique device identifier</>,
        <>Browser characteristics</>,
        <>Device characteristics</>,
        <>Operating system</>,
        <>Language preferences</>,
        <>Referring URLs</>,
        <>Information on actions taken on our websites</>,
        <>Dates and times of visits to our sites and other usage statistics</>,
        <>Location data</>,
      ])}

      {renderParagraphs([
        <>
          The kinds of sensitive personal information which we may process are:
        </>,
      ])}

      {renderParagraphs([
        <>
          You may also choose to provide your Aadhaar details for identification
          purposes. Please note that it is not mandatory for you to provide your
          Aadhaar details for identification purposes, and you may provide other
          identification documents such as passport or driving license.
        </>,
        <>
          Such sensitive personal information and Aadhaar informationis used by
          us on the basis of your consent, which you provide us when you click
          on [&quot;I agree&quot;] at the time of accessing our Website or
          otherwise, for the collection, storage, use, processing, sharing and/
          or transfer of such information, as applicable, in the manner provided
          under this Privacy Policy.
        </>,
        <>
          <em>
            <u>
              Legal Basis For Processing Of personal information For European
              Economic Area (EEA) Users
            </u>
          </em>
          : The legal basis for collecting and processing the personal
          information described above, will be on the following grounds,
          depending on the nature of the personal information concerned and the
          context in which we collect it:
        </>,
      ])}

      {renderParagraphs([
        <>
          If you have questions about or require further information concerning
          the legal basis on which we collect and use your personal information
          for any specific processing activity, please contact our grievance
          officer using the contact details provided given below.
        </>,
      ])}

      {renderList([
        <>passwords</>,
        <>
          financial information such as bank account, payment instruments etc.
          to the extent you provide the same
        </>,
      ])}

      {renderList([
        <>
          <strong>Name and contact data</strong>. We collect your first and last
          name, email address, postal address, phone number, and other similar
          contact data.
        </>,
        <>
          <strong>Credentials</strong>. We collect your username and password
          when you create your personal account on our websites.
        </>,
        <>
          <strong>Demographic data</strong>. We collect data about you such as
          your date of birth, Parents/Guardian Name, Photograph, Address, age,
          gender, country, blood group, citizenship and preferred language.
        </>,
        <>
          <strong>Educational and professional background</strong>. We collect
          data about your employment history, education history and possibly
          your standardized test scores.
        </>,
        <>
          <strong>Videos and pictures</strong>. Photographs or video recordings
          may be taken during our physical events; we may use the visual or
          video captures of your person on our websites or promotional material.
        </>,
        <>
          <strong>Other information</strong> you may provide to us, such as in
          feedback forms or through the “Contact Us” feature on our websites.
        </>,
      ])}

      {renderHeading("1.1 COOKIES, AND OTHER TRACKING WEBSITES")}

      {renderParagraphs([
        <>
          We also collect certain information through the use of cookies, web
          beacons, analytics/ webmaster tools and/ or similar tracking
          technologies. You have the option to either accept or refuse these
          cookies and know when a cookie is being sent to your computer. If you
          choose to refuse our cookies, you may not be able to use some portions
          of our Service. We may also use third parties to display and target
          ads, provide certain functionality (such as maps) or to place their
          own cookies and other tracking technologies to collect, track and
          analyze usage and statistical information from users. We are not
          responsible for the information collection practices of any third
          parties.
        </>,
        <>
          A “cookie” is a file that websites send to a visitor’s computer or
          other Internet-connected device to uniquely identify the visitor’s
          browser or to store information or settings in the browser. A “web
          beacon,” also known as an Internet tag, pixel tag or clear GIF, links
          webpages to web servers and their cookies and is used to transmit
          information collected through cookies back to a web server. Through
          these automated collection methods, we may obtain “clickstream data,”
          which is a log of the links and other content on which a visitor
          clicks while browsing a website.
        </>,
        <>
          As the visitor clicks through the website, a record of the action may
          be collected and stored. We link certain data elements we have
          collected through automated means, such as your browser information,
          with other information we have obtained about you to let us know, for
          example, whether you have opened an email we sent to you. Your browser
          may tell you how to be notified when you receive certain types of
          cookies or how to restrict or disable certain types of cookies. Please
          note, however, that without cookies you may not be able to use all of
          the features of our websites. To the extent required by applicable
          law, we will obtain your consent before collecting personal
          information using cookies or similar automated means.
        </>,
        <>
          The providers of third-party apps, tools, widgets and plug-ins on our
          Website, such as social media sharing tools, also may use automated
          means to collect information regarding your interactions with these
          features. This information is collected directly by the providers of
          the features and is subject to the privacy policies or notices of
          these providers.
        </>,
      ])}

      {renderHeading("1.2 PAYMENT INFORMATION")}

      {renderParagraphs([
        <>
          We might collect your billing name, billing address and payment method
          when you subscribe to NFS. We do not collect and store details like
          Credit / Debit Card numbers, PIN, CVV/CVC, Expiry Date, OTP, or any
          other information related to payment method/mode, we redirect the
          payee to the respective payment gateway page.
        </>,
      ])}

      {renderHeading("2. HOW DO WE USE YOUR INFORMATION")}

      {renderHeading(" 2.1 GENERAL", 17)}
      {renderParagraphs([
        <>
          We may use information to protect NFS, our students or our Website. We
          may disclose personal information when we are required to or we
          believe it is appropriate to comply with the law (e.g., a lawful
          subpoena, warrant or court order); to enforce or apply this Policy or
          our other policies or agreements; to initiate, render, bill, and
          collect for amounts owed to us; to protect our or our users’ rights,
          property or safety; to protect our users from fraudulent, abusive, or
          unlawful use of the Services; or if we believe that an emergency
          involving the danger of death or serious physical injury to any person
          requires or justifies disclosure of personal information or we may
          also share it if a government agency or investigatory body wants us to
          do so, or anytime when we are investigating potential fraud. We may
          use information for general legal and compliance requirements.
        </>,
        <>
          We use information as otherwise permitted by law. To such extent, we
          may share your information for reasons not described in this policy.
        </>,
      ])}

      {renderHeading("2.2 PERSONAL INFORMATION", 17)}

      {renderParagraphs([
        <>
          We use personal information to facilitate our Services, to process
          your requests or transactions, to provide you with information,
          products and services you request, to administer and assist us with
          the operation of our business, and for the purpose for which the
          information was provided. We may use the information we collect to
          send you news relevant to you or in accordance with your preferences.
          These might be our own offers or products, or third-party offers or
          products we think you might find interesting. We may also send you
          advertisements and promotional material from third parties.
        </>,
      ])}

      {(renderHeading("2.3 LEGAL USE"), 17)}
      {renderParagraphs([
        <>The information shall be used as otherwise permitted by law.</>,
      ])}

      {(renderHeading("2.4 THIRD PARTY SERVICE PROVIDERS"), 17)}
      {renderParagraphs([
        <>
          In the event that we engage with third party service providers in
          connection with our Services, we may share personal information with
          such service providers who need access to such information to carry
          out their work for us. For example, we may use credit card processing
          or verification companies to verify credit card information or to
          verify your identity. Other than such service providers, we do not
          share personal information with third parties unless (1) you have
          indicated to us that you wish to receive information from such
          parties; (2) you otherwise give us your consent to do so; or (3) in
          response to a request for information.
        </>,
      ])}

      {renderHeading("2.5 TRANSACTIONAL COMMUNICATIONS", 17)}

      {renderParagraphs([
        <>
          The information provided by you will be used to contact you for
          confirmation of our educational services. We might send emails or SMS,
          WhatsApp messages, or Calls or Invite in Events offline/online in case
          you are a Subscribed User. We provide all Users with the opportunity
          to opt-out of receiving non-essential (promotional, marketing-related)
          communications from us on behalf of our partners, and from us in
          general, after providing Us with personal information. If the User
          wants to opt-out from receiving promotional SMS, he/she please
          register your phone number with DND processes as per our Terms &amp;
          Conditions of Use.
        </>,
      ])}

      {renderHeading("3. DISCLOSURES AND TRANSFER")}

      {renderParagraphs([
        <>
          All sensitive personal information that we collect is disclosed or
          transferred only under the following circumstances and to
          entities/individuals who adhere to the same levels of security
          standards as maintained by us, to ensure the security, integrity, and
          privacy of your sensitive personal information. Such
          entities/individuals are identified below:
        </>,
        <>
          <em>Disclosures To Affiliates/Group Companies</em>: We and our
          affiliates/group companies receive such information from and may share
          such information with each other for (a) research and development in
          relation to the provision of the relevant product or service; (b)
          marketing and advertising the relevant product or service to you; (c)
          tailoring product or service offerings for your benefit; or (d)
          driving analytics on how our services are used and improving our
          services, our affiliates’ services and your experiences using them.
        </>,
        <>
          <em>Mandatory Disclosures Or Transfers</em>: As required by (a)
          applicable laws; or (b) any judicial or governmental proceeding.
        </>,
        <>
          [<em>Disclosures Or Transfers To Service Providers: </em>We will share
          such information with third parties who perform services on our behalf
          or to the extent we may be providing any third-party services to you
          as set out in paragraph 2.3 above]
        </>,
        <>
          <em>Corporate Changes:</em> In connection with the sale of our
          business or assets or an acquisition of our business by a third party
          or any other M&amp;A transaction involving us.
        </>,
      ])}

      {renderHeading("TRANSFER FROM THE EEA")}

      {renderParagraphs([
        <>
          Personal information of EEA data subjects are transferred outside the
          EEA to jurisdictions that have an “adequate level of protection” as
          determined by the European Commission. In case of other jurisdictions,
          such as India, we do so on the basis of lawful measures, such as
          contracts that include the EU standard contractual clauses.
        </>,
      ])}

      {renderHeading("4. DATA RETENTION")}

      {renderParagraphs([
        <>
          All personal information including Aadhaar details collected by us
          will be retained only as long as it is necessary for the purposes for
          which it is collected. To determine such duration, we usually consider
          the: (a) quantum, sensitivity, and nature of personal information; (b)
          purposes of processing the personal information, and alternative means
          to achieve those purposes; (c) potential risk of harm from
          unauthorized use of such personal information; and (d) legal
          requirements in relation to the personal information.
        </>,
      ])}

      {renderHeading("5. PERSONAL INFORMATION OF MINORS")}

      {renderParagraphs([
        <>
          Our services and Website are not directed at persons under the age of
          16, and we do not knowingly collect personal or sensitive personal
          information from persons under the age of 16. In relation to personal
          information collected from persons between the ages of 16 and 18, we
          assume that the legal guardian or parents have consented to such
          collection and such continued use is made available by the legal
          guardian or parents. If you are a parent/guardian and believe your
          child has provided us with their personal information without your
          consent, please contact our grievance officer whose contact details
          are given below.
        </>,
      ])}

      {renderHeading("6. YOUR RIGHTS")}

      {renderParagraphs([
        <>
          You have the following rights under this Privacy Policy in relation to
          your personal information:
        </>,
        <>
          <em>Right of Access And Modification</em>: You can access your
          personal information at any time to review any such information that
          you have supplied. You may modify any such information that has been
          found to be inaccurate or incomplete during such review.
        </>,
        <>
          <em>Right to Withdraw Consent:</em> You may also withdraw your consent
          in relation to processing of any sensitive personal information that
          you have provided to us, by contacting our grievance officer, using
          the details provided below. Please note however that this may affect
          our ability to provide you with services and may therefore lead to
          discontinuation of such services for which this information was being
          used for, at our sole discretion.
        </>,
      ])}

      {renderHeading("5(A) Additional Rights of EEA Users", 17)}

      {renderParagraphs([
        <>
          <em>Right to Erasure</em>: You have the right to request that we erase
          your personal information where one of the following grounds applies:
        </>,
      ])}

      {renderList([
        <>
          you believe that the personal information is no longer necessary in
          relation to the purposes for which it was collected or otherwise
          processed;
        </>,
        <>
          you withdraw your consent based on which the processing of the
          personal information was carried out and there is no other legal
          ground for the processing;
        </>,
        <>
          you object to the processing of the personal information in accordance
          with applicable laws and there are no overriding legitimate grounds
          for the processing;
        </>,
        <>
          you believe that your personal information has been unlawfully
          processed.
        </>,
      ])}

      {renderParagraphs([
        <>
          However, we will have the ability to refuse your request for the right
          to erasure in certain circumstances, as may be permitted under
          applicable laws.
        </>,
        <>
          <em>Right To Withdraw Consent:</em> If we have collected and processed
          your personal information based on your consent, you can withdraw your
          consent at any time. Withdrawing your consent will not affect (a) the
          lawfulness of any processing we conducted prior to your withdrawal, or
          (b) processing your personal information under other legal basis noted
          above.
        </>,
        <>
          <em>Other Rights:</em> You have the right (in accordance with
          applicable laws) to: (a) object to processing of your personal
          information; (b) request us to restrict processing of your personal
          information; and (iii) request portability of your personal
          information. You can exercise these rights by contacting our grievance
          officer whose contact details are provided below.
        </>,
        <>
          If you believe we are using your personal information in a way that is
          inconsistent with this Privacy Policy or for more information about
          your rights or in case you wish to make a complaint, you have the
          right to approach your local data protection authority.
        </>,
      ])}

      {renderHeading(" 7. DISCLAIMER")}

      {renderParagraphs([
        <>
          Subscribed Users are solely responsible for maintaining the
          confidentiality of the passcode and user identification and all
          activities and transmission performed by the Subscribed Users through
          his user identification and shall be solely responsible for carrying
          out any online or off-line transaction. NFS assumes no responsibility
          or liability for their improper use of information relating to such
          usage of credit cards / debit cards used by the mapper
          online/off-line. All the disclaimers mentioned in Terms &amp;
          Conditions of Use of the Website shall be applicable.
        </>,
      ])}

      {renderHeading("8. SECURITY")}

      {renderParagraphs([
        <>
          We are strive to ensure the security, integrity, confidentiality and
          privacy of your information. We provide physical, electronic, and
          procedural safeguards to protect information we process and maintain
          and have implemented reasonable security practices commensurate to the
          standards required under applicable laws. For example, we limit access
          to this Information to authorized employees only who need to know that
          information in order to operate, develop or improve our
          application/Services/products/Website. Please be aware that, although
          we endeavor to provide reasonable security for information we process
          and maintain, no security system can prevent all potential security
          breaches.
        </>,
      ])}

      {renderHeading(" 9. JURISDICTION")}

      {renderParagraphs([
        <>
          If you choose to visit the Website and/or application, your visit and
          any dispute over privacy is subject to this Policy and the Terms &amp;
          Conditions of Use. In addition to the foregoing, any disputes arising
          under this Policy shall be governed by the laws of India, and courts
          at Kota, Rajasthan shall have exclusive jurisdiction over such
          disputes.
        </>,
      ])}

      {renderHeading("10. MISCELLANEOUS")}

      {renderParagraphs([
        <>
          This Privacy Policy is an electronic contract formed under The
          Information Technology Act, 2000 and the rules made thereunder and the
          amended provisions pertaining to electronic documents / records in
          various statutes as amended by The Information Technology Act, 2000.
        </>,
        <>
          We may update our Privacy Policy from time to time. Thus, we advise
          you to review this page periodically for any changes. We will try to
          notify you of any changes by posting the new Privacy Policy on this
          page, but may not be able to separately notify you of the revisions
          each time that we make them. These changes would be effective
          immediately, after they are posted on this page. We will not be
          responsible for your failure to remain informed about such changes.
          However, where required under applicable law, we will obtain
          additional consent from you for such changes.
        </>,
      ])}

      {renderHeading("GRIEVANCE OFFICER")}

      {renderParagraphs([
        <>
          If you have any concerns or questions in relation to this Privacy
          Policy or would like to avail any of the rights provided to you under
          this Privacy Policy, you may write to our grievance officer whose
          details are as follows:
        </>,
        <>
          Name:
          <strong>
            <em>Ganesh Deshpande</em>
          </strong>
          <br /> Designation:
          <strong>
            <em>Grievance Officer</em>
          </strong>
          <br /> Email:
          <u>
            <a href="mailto:grievance@netizens.edu.in">
              <em>grievance@netizens.edu.in</em>
            </a>
          </u>
        </>,
      ])}
      <Button
                        variant="contained"

        sx={{
          float: "right",
        }}
        onClick={handleClose}
      >
        Close
      </Button>
    </AppDialog>
  );
};

export default React.memo(PrivacyPolicy);

PrivacyPolicy.propTypes = {
  isPrivacyPolicyOpen: PropTypes.bool,
  setIsPrivacyPolicyOpen: PropTypes.func,
};
