import { USER_ROLE } from "constants/AppConstant";
import React from "react";
import { BiSolidReport } from "react-icons/bi";

const AdmissionStudentMyReport = React.lazy(
  () => import("modules/student/admission/admissionStudentMyReport")
);

const routes = {
  permittedRole: [USER_ROLE.STUDENT],
  title: "My Reports",
  path: ["/student/myreports"],
  element: <AdmissionStudentMyReport />,
  type: "item",
  icon: <BiSolidReport />,
};

export default routes;