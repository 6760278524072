import React from "react";
import { USER_ROLE } from "constants/AppConstant";

const EntrancePayment = React.lazy(() =>
  import("modules/student/entrance/entranceStudentPayment")
);

const routes = {
  permittedRole: [USER_ROLE.ENTRANCE_STUDENT],
  path: "/entrance/payment",
  element: <EntrancePayment />,
};

export default routes;
