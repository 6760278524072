// dispatch types is constant object for dispatch types in reducer
import { DISPATCH_TYPES } from "constants/ActionTypes";

const reducer = (state, action) => {
  switch (action.type) {
    // It will set all fetched records in state
    case DISPATCH_TYPES.GET_ALL:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
      };
    // It will set all fetched records in state
    case DISPATCH_TYPES.EXPORT:
      return {
        ...state,
        exportList: action.payload.data,
      };
  }
};

export default reducer;
