import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const StudentInventoryTransactions = React.lazy(() =>
    import("modules/inventory/studentInventoryTransactions")
);

const routes = {
    element: <StudentInventoryTransactions />,
    title: "Student Transactions",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: [
        "/studentinventorytransactions"
    ],
};

export default routes;
