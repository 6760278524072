import { USER_ROLE } from "constants/AppConstant";
import liveClassScheduleRoutes from "./liveClassScheduleRoutes";
import { MdLiveTv } from "react-icons/md";

const menu = {
    id: "Live Class",
    title: "Live Class",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    type: "collapse",
    icon: <MdLiveTv />,
    path: ["/liveClassMenu"],
    children: [liveClassScheduleRoutes],
};

export default menu;
