import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const UserRegularWorkingSchedule = React.lazy(() => import("modules/user/userRegularWorkingSchedule"));

const routes = {
    title: "Regular Working Schedule",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ["/user/regularworkingschedules"],
    element: <UserRegularWorkingSchedule />,
    type: "item",
};

export default routes;
