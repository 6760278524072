import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const LockerSetup = React.lazy(
  () => import("modules/master/system/lockerSetup")
);

const routes = {
  element: <LockerSetup />,
  title: "Locker Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    "/lockers",

  ],
};

export default routes;
