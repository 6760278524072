import React from "react";
import { Icon, ListItem, ListItemText } from "@mui/material";
import clsx from "clsx";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import AppNavLink from "../../../AppNavLink";
import Badge from "@mui/material/Badge";
import { useLocation } from "react-router-dom";
import { useAppSettingContext } from "contexts/appSetting";
import { FONTS } from "constants/AppEnum";

function HorizontalItem(props) {
  const { item, dense } = props;

  const location = useLocation();
  const active = isUrlInChildren(item, location.pathname);

  const { appSetting } = useAppSettingContext();
  const { sidebarMenuSelectedBgColor, sidebarMenuSelectedTextColor } =
    appSetting.sidebar;

  function isUrlInChildren(parent, path) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (isUrlInChildren(parent.children[i], path)) {
          return true;
        }
      }

      if (
        parent.children[i].path === path ||
        path.includes(parent.children[i].path)
      ) {
        return true;
      }
    }

    return false;
  }

  if (!item?.hasPermission) {
    return null;
  }

  return (
    <ListItem
      component={AppNavLink}
      to={item.path?.[0]}
      activeClassName="active"
      className={clsx(
        "navItem",
        isUrlInChildren(item.path?.[0], location.pathname) && "active"
      )}
      exact={item.exact}
      sx={{
        textTransform: "uppercase",
        minHeight: 40,
        span: {
          fontSize: 16,
          fontWeight: FONTS.BOLD,
        },
        padding: "4px 12px",
        fontWeight: "bold !important",
        color: (theme) => theme.palette.text.primary,
        textDecoration: "none!important",
        ".svgIcon": {
          color: (theme) => theme.palette.primary.main,
          fontSize: 24,
        },
        "&.active": {
          backgroundColor: sidebarMenuSelectedBgColor,
          color: sidebarMenuSelectedTextColor + "!important",
          pointerEvents: "none",
          "& .list-item-text-primary": {
            color: "inherit",
          },
          "& .list-item-icon": {
            color: "inherit",
          },
          ".svgIcon": {
            color: "inherit",
          },
        },
        "& .list-item-text": {
          padding: "0 0 0 16px",
        },
        "&.dense": {
          padding: "4px 12px",
          minHeight: 40,
          "& .list-item-text": {
            padding: "0 0 0 8px",
          },
        },
      }}
    >
      {item.icon && (
        <Icon
          sx={{
            color: active ? sidebarMenuSelectedTextColor : "action",
            mr: 3,
            fontSize: { xs: 16, xl: 18 },
          }}
          className="svgIcon"
        >
          {item.icon}
        </Icon>
      )}
      <ListItemText className="AppNavLinkTextSubmenu" primary={item.title} />
      {item.count && (
        <Box ml={4}>
          <Badge
            count={item.count}
            sx={{
              color: item.color,
            }}
          />
        </Box>
      )}
    </ListItem>
  );
}

export default React.memo(HorizontalItem);
