import { USER_ROLE } from "constants/AppConstant";
import React from "react";
import { FaNewspaper } from "react-icons/fa6";

const AdmissionStudentMyExam = React.lazy(() =>
  import("modules/student/admission/admissionStudentMyExam")
);

const routes = {
  permittedRole: [USER_ROLE.STUDENT],
  title: "My Exam",
  path: ["/student/myexams"],
  element: <AdmissionStudentMyExam />,
  type: "item",
  icon: <FaNewspaper />,
};

export default routes;
