import PropTypes from "prop-types";
import StudentAttendanceDeviceLogProvider from "./studentAttendanceDeviceLogContext";
import StudentAttendanceReportProvider from "./studentAttendanceReportContext";
import StudentManualAttendanceProvider from "./studentManualAttendanceContext";
import UserAttendanceLogProvider from "./userAttendanceLogContext";
import UserExtraWorkingScheduleProvider from "./userExtraWorkingScheduleContext";
import UserShiftWiseAttendanceLogProvider from "./userShiftWiseAttendanceLogContext";


const AttendanceProvider = ({ children }) => {
    return (
        <UserExtraWorkingScheduleProvider>
            <UserShiftWiseAttendanceLogProvider>
                <UserAttendanceLogProvider>
                    <StudentAttendanceReportProvider>
                        <StudentManualAttendanceProvider>
                            <StudentAttendanceDeviceLogProvider>
                                {children}
                            </StudentAttendanceDeviceLogProvider>
                        </StudentManualAttendanceProvider>
                    </StudentAttendanceReportProvider>
                </UserAttendanceLogProvider>
            </UserShiftWiseAttendanceLogProvider>
        </UserExtraWorkingScheduleProvider>
    );
};

export default AttendanceProvider;

AttendanceProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
