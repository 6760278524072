import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { buttonStyles } from "modules/student/entrance/entranceStudentRegistrationList/Header/HeaderForm";
import PropTypes from "prop-types";
import { useState } from "react";
import AppIconButton from "../AppIconButton";
import AppMessageContent from "./AppMessageContent";

const AppMessages = ({
  drawerPosition,
  tooltipPosition,
  isMenu,
  sxMessageContentStyle,
}) => {
  const [showMessage, setShowMessage] = useState(false);
  return (
    <>
      {isMenu ? (
        <Box
          component="span"
          onClick={() => setShowMessage(true)}
        >
          Message
        </Box>
      ) : (
        <AppIconButton
          title="Message"
          placement={tooltipPosition}
          icon={<EmailOutlinedIcon className="icon" />}
          sx={buttonStyles}
          buttonProps={{
            onClick: () => setShowMessage(true),
          }}
        />
      )}

      <Drawer
        anchor={drawerPosition}
        open={showMessage}
        onClose={() => setShowMessage(false)}
      >
        <AppMessageContent
          sxStyle={sxMessageContentStyle}
          onClose={() => setShowMessage(false)}
        />
      </Drawer>
    </>
  );
};

export default AppMessages;

AppMessages.defaultProps = {
  drawerPosition: "right",
  tooltipPosition: "bottom",
};

AppMessages.propTypes = {
  sxMessageContentStyle: PropTypes.object,
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
};
