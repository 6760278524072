import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const SMSSetup = React.lazy(() => import("modules/master/communication/sms"));

const routes = {
  element: <SMSSetup />,
  title: "SMS Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/sms/templates", "/sms/gateways", "/sms/senders"],
};

export default routes;
