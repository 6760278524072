import SchoolIcon from "@mui/icons-material/School";
import { USER_ROLE } from "constants/AppConstant";
import academicSetupRoutes from "./academicSetupRoutes";
import admissionSetupRoutes from "./admissionSetupRoutes";
import classSetupRoutes from "./classSetupRoutes";
import feesSetupRoutes from "./feesSetupRoutes";
import idCardRoutes from "./idCardRoutes";
import identityCardSetupRoutes from "./identityCardSetupRoutes/index";
import schoolInfoSetupRoutes from "./schoolInfoSetupRoutes";
import subjectSetupRoutes from "./subjectSetupRoutes";
import timeTableSetupRoutes from "./timeTableSetupRoutes";

export default {
  id: "Academic ",
  title: "Academic",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: "collapse",
  icon: <SchoolIcon />,
  path: ["/academicMenu"],

  children: [
    academicSetupRoutes,
    admissionSetupRoutes,
    schoolInfoSetupRoutes,
    classSetupRoutes,
    timeTableSetupRoutes,
    feesSetupRoutes,
    subjectSetupRoutes,
    identityCardSetupRoutes,
    idCardRoutes,
  ],
};
